import React from "react";

import {
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuShortcut,
} from "@/components/ui/dropdown-menu";

import { FilterValue } from "./types";

interface FilterItemProps {
  field: string;
  filter: FilterValue;
  onSelect: (prev: FilterValue) => void;
  onClose: () => void;
}

export function FilterItem({
  field,
  filter,
  onSelect,
  onClose,
}: FilterItemProps) {
  switch (filter.type) {
    case "select":
      if (!("options" in filter)) return null;

      return (
        <DropdownMenuGroup
          onSelect={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          key={field}
        >
          {filter.options.map((option: string) => (
            <DropdownMenuItem
              className="capitalize cursor-pointer"
              key={option}
              onSelect={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelect({ ...filter, value: option });
                onClose();
              }}
            >
              {option.toString()}
              <DropdownMenuShortcut>
                {option.toString().charAt(0).toUpperCase()}
              </DropdownMenuShortcut>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      );
    case "string":
      return (
        <DropdownMenuItem
          onSelect={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          key={field}
        >
          <label htmlFor={field} className="block mb-1 text-sm font-medium">
            {field}
          </label>
          <input
            id={field}
            type="text"
            className="w-full border rounded p-1"
            placeholder="Enter text..."
          />
        </DropdownMenuItem>
      );
    case "date":
      return (
        <DropdownMenuItem
          onSelect={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          key={field}
        >
          <label htmlFor={field} className="block mb-1 text-sm font-medium">
            {field}
          </label>
          <select
            id={`${field}-comparator`}
            className="w-full border rounded p-1 mb-1"
          >
            <option value="is">is</option>
            <option value="is not">is not</option>
            <option value="is after">is after</option>
            <option value="is before">is before</option>
            <option value="same date">same date</option>
          </select>
          <input id={field} type="date" className="w-full border rounded p-1" />
        </DropdownMenuItem>
      );
    case "number":
      return (
        <DropdownMenuItem
          onSelect={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          key={field}
        >
          <label htmlFor={field} className="block mb-1 text-sm font-medium">
            {field}
          </label>
          <select
            id={`${field}-comparator`}
            className="w-full border rounded p-1 mb-1"
          >
            <option value="equals">equals</option>
            <option value="greater than">greater than</option>
            <option value="less than">less than</option>
          </select>
          <input
            id={field}
            type="number"
            className="w-full border rounded p-1"
            placeholder="Enter number..."
          />
        </DropdownMenuItem>
      );
    default:
      return null;
  }
}
