import { Tokens } from "@dfinity/ledger-icp";
import { Principal } from "@dfinity/principal";
import { AvatarFallback } from "@radix-ui/react-avatar";
import {
  ColumnDef,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Home, Trash2Icon } from "lucide-react";
import React, { ComponentPropsWithoutRef } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { toast } from "sonner";

import IcpAlert from "@/components/icp-alert-settings";
import MemoryAlerts from "@/components/memory-alerts";
import PrincipalAbbr from "@/components/principal-abbr";
import { SiteBreadcrumb } from "@/components/site-breadcrumb";
import TableSkeleton from "@/components/table-skeleton";
import TopupAlerts from "@/components/topup-alerts";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import Hashatar from "@/components/ui/hashatar";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Sidebar,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import EmailConf from "@/coordination/settings/email-config";
import {
  CustomerMetadataForm,
  TeamCreationForm,
} from "@/hooks/forms/customer-metadata";
import {
  NotificationSettings,
  useCustomerMetadataQuery,
  useCustomerNotificationSettingsMutation,
  useCustomerNotificationSettingsQuery,
  useCustomerPaymentConfQuery,
} from "@/hooks/queries/customer";
import {
  AppLink,
  Member,
  Role,
  useCurrentTeamQuery,
  useRoute,
  useTeamAddMemberMutation,
  useTeamDeleteMutation,
  useTeamMemberPermissionMutation,
  useTeamMemberRemovalMutation,
  useTeamMembersQuery,
} from "@/hooks/queries/team";
import { cn } from "@/lib/ui-utils";
import { useIdp } from "@/state/stores/idp";

import CyclesAllowanceAlert from "../../cycles-allowance-alert-settings";
import { DataTableColumnHeader } from "../../data-table/column-header";
import { ExperimentalFeatures } from "../../experimental-features";
import { Layout, TopBar } from "./layout";

export interface SettingsProps extends ComponentPropsWithoutRef<"div"> {
  title: string;
  headChildren?: React.ReactNode;
}

export default function SettingsPage({
  children,
  headChildren,
  className,
  ...props
}: SettingsProps) {
  const team = useCurrentTeamQuery();

  return (
    <Layout {...props}>
      <TopBar>
        <SiteBreadcrumb
          items={[
            {
              title: "Settings",
              href: "/settings",
            },
            {
              title: props.title,
            },
          ]}
        />
        <div>{headChildren}</div>
      </TopBar>
      <div
        className={cn(
          "border border-border/75 flex flex-1 flex-col gap-4 p-4 relative bg-table rounded-lg overflow-hidden",
          className
        )}
      >
        <div className="flex bg-table relative min-h-full">
          <SettingsSidebar />
          <div className="flex-1">{children}</div>
        </div>
      </div>
    </Layout>
  );
}

function SettingsSidebar() {
  const location = useLocation();
  const route = useRoute();
  const { state } = useSidebar();
  const team = useCurrentTeamQuery();

  // Return null if main sidebar is expanded
  if (state === "expanded") return null;

  const menuItems = [
    {
      label: "General",
      path: () => route("/settings"),
    },
    {
      label: "Alerts",
      path: () => route("/settings/alerts"),
    },
    {
      label: "Experiments",
      path: () => route("/settings/experiments"),
    },
    ...(team.data
      ? [
          {
            label: "Members",
            path: () => route(`/settings/members`),
          },
        ]
      : []),
  ];

  return (
    <div className="mr-4">
      <Sidebar
        collapsible="none"
        className="w-64 border-r border-border/50 sticky top-2 h-auto p-2 pr-4 bg-transparent"
      >
        <SidebarMenu>
          {menuItems.map((item) => (
            <SidebarMenuItem key={item.label}>
              <AppLink to={item.path()}>
                <SidebarMenuButton
                  className={cn(
                    "bg-muted/0 transition-colors duration-100",
                    item.path() === location.pathname &&
                      "bg-muted/50 border border-border/50",
                    "hover:bg-muted"
                  )}
                >
                  {item.label}
                </SidebarMenuButton>
              </AppLink>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </Sidebar>
    </div>
  );
}

interface DefaultSettingsProps {
  topupAlertSettings?: NotificationSettings;
  topupAlertSaveHandler: (settings: NotificationSettings) => void;
  notificationsLoading?: boolean;

  lowICPBalanceAlertSettings: { threshold: number; enabled: boolean };
  lowICPBalanceAlertSaveHandler: (settings: {
    threshold: number;
    enabled: boolean;
  }) => void;
  lowICPBalanceAlertLoading?: boolean;

  lowCyclesAllowanceAlertSettings: { threshold: number; enabled: boolean };
  lowCyclesAllowanceAlertSaveHandler: (settings: {
    threshold: number;
    enabled: boolean;
  }) => void;
  lowCyclesAllowanceAlertLoading?: boolean;

  memoryAlertSettings: { notifyOnMemoryThresholdReached?: boolean };
  memoryAlertSaveHandler: (enabled: boolean) => void;
  memoryAlertLoading?: boolean;
}

export function DefaultSettings() {
  // TODO: This component is a mess.
  const paymentConf = useCustomerPaymentConfQuery();
  const { mutateAsync: updateNotificationSettings, isPending: mutateLoading } =
    useCustomerNotificationSettingsMutation();

  const { data: notificationSettings, isPending: queryLoading } =
    useCustomerNotificationSettingsQuery();

  const handleNotificationSettingsSave = React.useCallback(
    async (settings: NotificationSettings) => {
      if (!notificationSettings) return;
      await updateNotificationSettings({
        ...notificationSettings,
        ...settings,
      });
    },
    [notificationSettings]
  );

  const loading = mutateLoading || queryLoading;

  const handleIcpAlertSave = React.useCallback(
    async ({ enabled, threshold }: { threshold: number; enabled: boolean }) => {
      if (!notificationSettings) return;
      // threshold validation
      if (enabled && threshold < 0.1) {
        toast.error(
          "Must set a low ICP balance threshold greater than 0.1 ICP"
        );
        return;
      }
      const notifyOnICPBelow: Tokens | undefined =
        enabled && threshold
          ? { e8s: BigInt(threshold * 100_000_000) }
          : undefined;
      await updateNotificationSettings({
        ...notificationSettings,
        notifyOnICPBelow,
      });
    },
    [notificationSettings]
  );

  const lowCyclesAllowanceAlertSaveHandler = React.useCallback(
    async ({ enabled, threshold }: { threshold: number; enabled: boolean }) => {
      if (!notificationSettings) return;
      // threshold validation
      if (enabled && threshold < 0.1) {
        toast.error(
          "Must set a low cycles balance threshold greater than 0.1 cycles"
        );
        return;
      }
      const notifyOnCyclesApprovalBalanceBelow =
        enabled && threshold ? BigInt(threshold * 1e12) : undefined;
      await updateNotificationSettings({
        ...notificationSettings,
        notifyOnCyclesApprovalBalanceBelow,
      });
    },
    [notificationSettings]
  );

  const handleMemoryAlertSave = React.useCallback(
    async (enabled: boolean) => {
      if (!notificationSettings) return;
      await updateNotificationSettings({
        ...notificationSettings,
        notifyOnMemoryThresholdReached: enabled,
      });
    },
    [notificationSettings]
  );

  // derived low icp balance settings prop
  const lowICPBalanceAlertSettings =
    notificationSettings?.notifyOnICPBelow === undefined
      ? { enabled: false, threshold: 0 }
      : {
          enabled: true,
          threshold:
            Number(notificationSettings?.notifyOnICPBelow.e8s) / 100_000_000,
        };

  // derived cycles low balance settings prop
  const lowCyclesAllowanceAlertSettings =
    notificationSettings?.notifyOnCyclesApprovalBalanceBelow === undefined
      ? { enabled: false, threshold: 0 }
      : {
          enabled: true,
          threshold:
            Number(notificationSettings?.notifyOnCyclesApprovalBalanceBelow) /
            1e12,
        };

  return (
    <SettingsPage title="Alerts">
      <div className="flex flex-col gap-5">
        <>
          <EmailConf />
          <TopupAlerts
            settings={notificationSettings}
            handler={handleNotificationSettingsSave}
            loading={loading}
          />
          {paymentConf.data?.paymentMethod ? (
            "icp" in paymentConf.data.paymentMethod ? (
              <IcpAlert
                settings={lowICPBalanceAlertSettings}
                handler={handleIcpAlertSave}
                loading={loading}
              />
            ) : (
              <CyclesAllowanceAlert
                settings={lowCyclesAllowanceAlertSettings}
                handler={lowCyclesAllowanceAlertSaveHandler}
                loading={loading}
              />
            )
          ) : null}
          <MemoryAlerts
            settings={{
              notifyOnMemoryThresholdReached:
                notificationSettings?.notifyOnMemoryThresholdReached,
            }}
            handlers={{ save: handleMemoryAlertSave }}
            loading={loading}
          />
        </>
      </div>
    </SettingsPage>
  );
}

export function useMockDefaultSettingsProps(): DefaultSettingsProps {
  const [notificationsLoading, setNotificationsLoading] = React.useState(false);
  const [lowICPBalanceAlertLoading, setLowICPBalanceAlertLoading] =
    React.useState(false);
  const [lowCyclesAllowanceAlertLoading, setLowCyclesAllowanceAlertLoading] =
    React.useState(false);
  const [notifyOnMemoryThresholdReached, setNotifyOnMemoryThresholdReached] =
    React.useState(false);
  const [memoryAlertLoading, setMemoryAlertLoading] = React.useState(false);
  const topupAlertSaveHandler = React.useCallback(
    async (settings: NotificationSettings) => {
      setNotificationsLoading(true);
      await new Promise((res) => {
        setTimeout(res, 1000);
      });
      setNotificationsLoading(false);
      toast(
        `STORYBOOK: Saved notification settings: failure ${settings.notifyOnTopupFailure}, success ${settings.notifyOnTopupSuccess}`
      );
    },
    []
  );
  const lowICPBalanceAlertSaveHandler = React.useCallback(
    async (settings: { enabled: boolean; threshold: number }) => {
      setLowICPBalanceAlertLoading(true);
      await new Promise((res) => {
        setTimeout(res, 1000);
      });
      setLowICPBalanceAlertLoading(false);
      toast(
        `STORYBOOK: Saved ICP alert settings: ${settings.threshold}ICP, enabled ${settings.enabled}`
      );
    },
    []
  );
  const lowCyclesAllowanceAlertSaveHandler = React.useCallback(
    async (settings: { enabled: boolean; threshold: number }) => {
      setLowCyclesAllowanceAlertLoading(true);
      await new Promise((res) => {
        setTimeout(res, 1000);
      });
      setLowCyclesAllowanceAlertLoading(false);
      toast(
        `STORYBOOK: Saved cycles alert settings: ${settings.threshold} cycles, enabled ${settings.enabled}`
      );
    },
    []
  );
  const memoryAlertSaveHandler = React.useCallback(async (enabled: boolean) => {
    setMemoryAlertLoading(true);
    await new Promise((res) => {
      setTimeout(res, 1000);
    });
    setMemoryAlertLoading(false);
    toast(`STORYBOOK: Saved memory alert settings: enabled ${enabled}`);
  }, []);

  const topupAlertSettings = {
    notifyOnTopupFailure: true,
    notifyOnTopupSuccess: true,
    notifyOnICPBelow: { e8s: 20_000_00 as unknown as bigint },
    notifyOnMemoryThresholdReached: false,
  };

  const lowICPBalanceAlertSettings = {
    enabled: false,
    threshold: 0.5,
  };

  const lowCyclesAllowanceAlertSettings = {
    enabled: false,
    threshold: 1,
  };

  return {
    notificationsLoading,
    lowICPBalanceAlertLoading,
    memoryAlertLoading,
    topupAlertSettings,
    lowICPBalanceAlertSettings,
    memoryAlertSaveHandler,
    lowICPBalanceAlertSaveHandler,
    memoryAlertSettings: { notifyOnMemoryThresholdReached },
    topupAlertSaveHandler,
    lowCyclesAllowanceAlertSettings,
    lowCyclesAllowanceAlertSaveHandler,
    lowCyclesAllowanceAlertLoading,
  };
}

export function ProfileSettings(props: Omit<SettingsProps, "title">) {
  const customer = useCustomerMetadataQuery();
  const team = useCurrentTeamQuery();

  return (
    <SettingsPage {...props} title="General" className="flex flex-col gap-8">
      <div className="flex flex-col-reverse md:grid grid-cols-[1fr,300px] gap-8">
        <Card>
          <CardHeader>
            <CardTitle>Profile metadata</CardTitle>
            <CardDescription>
              Edit your profile information and avatar
            </CardDescription>
          </CardHeader>
          <CardContent>
            <CustomerMetadataForm />
          </CardContent>
        </Card>
        <Card>
          <CardContent className="flex flex-col items-center justify-center h-full gap-4 text-center">
            <Avatar className="h-24 w-24" radius="rounded-full">
              <AvatarImage src={customer.data?.logoUrl} />
              <AvatarFallback>
                <Hashatar
                  name={customer.data?.username || "undefined"}
                  radius="rounded-full"
                />
              </AvatarFallback>
            </Avatar>
            {customer.isLoading ? (
              <>
                <Skeleton className="w-24 h-7" />
                <Skeleton className="w-32 h-5" />
              </>
            ) : (
              <>
                <div className="text-xl">{customer.data?.displayName}</div>
                <div className="text-sm text-muted-foreground">
                  {customer.data?.username}
                </div>
              </>
            )}
          </CardContent>
        </Card>
        {team.data && (
          <Card>
            <CardHeader>Delete Team</CardHeader>
            <CardContent className="text-sm">
              The team will be permanently deleted. This action cannot be
              undone.
            </CardContent>
            <CardFooter>
              <Dialog>
                <DeleteTeamDialog />
                <DialogTrigger asChild>
                  <Button variant="outline">Delete Team</Button>
                </DialogTrigger>
              </Dialog>
            </CardFooter>
          </Card>
        )}
      </div>
    </SettingsPage>
  );
}

function DeleteTeamDialog() {
  const { mutate, isPending, isSuccess } = useTeamDeleteMutation();

  if (isSuccess) return <Navigate to="/app" />;

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Delete Team</DialogTitle>
        <DialogDescription>
          Are you sure you want to delete this team?
        </DialogDescription>
      </DialogHeader>
      <form
        onSubmit={(e) => {
          mutate();
          e.preventDefault();
        }}
      >
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" type="button">
              Cancel
            </Button>
          </DialogClose>
          <Button type="submit" loading={isPending} disabled={isPending}>
            Continue
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  );
}

export function CreateTeamDialog({ handleClose }: { handleClose: () => void }) {
  const navigate = useNavigate();

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Create Team</DialogTitle>
        <DialogDescription>
          Create a new team to collaborate with others.
        </DialogDescription>
      </DialogHeader>
      <TeamCreationForm
        done={(r) => {
          handleClose();
          navigate(`/app/team/${r.username}`);
        }}
      />
    </DialogContent>
  );
}

interface MembersSettingsProps {}

function DisplayNameCell({ row }: { row: Row<Member> }) {
  return (
    <div className="flex items-center gap-3">
      <Avatar className="h-12 w-12" radius="rounded-full">
        <AvatarImage src={row.original.logoUrl} />
        <AvatarFallback>
          <Hashatar
            name={row.original.principal.toString()}
            radius="rounded-full"
          />
        </AvatarFallback>
      </Avatar>
      {row.original.displayName}
    </div>
  );
}

function RoleCell({ row }: { row: Row<Member> }) {
  const team = useCurrentTeamQuery();
  const members = useTeamMembersQuery(team.data?.principal);
  const { principal } = useIdp();

  const { mutate } = useTeamMemberPermissionMutation();

  const [value, setValue] = React.useState(row.original.role);

  const isSelf = row.original.principal.toText() === principal.toText();
  const isAdmin =
    members.data?.find(({ principal: p }) => p.toText() === principal.toText())
      ?.role === "admin";

  if (!isAdmin || isSelf)
    return <span className="capitalize">{row.original.role}</span>;

  return (
    <Select
      value={value}
      onValueChange={(e) => {
        setValue(e as "admin" | "member");
        mutate({
          member: row.original.principal,
          role: e as "admin" | "member",
        });
      }}
    >
      <SelectTrigger className="w-40">
        <SelectValue />
      </SelectTrigger>
      <SelectContent position="popper">
        <SelectItem value="admin">
          Admin
          {/* <div className="text-xs text-muted-foreground">
            Has full control of the team
          </div> */}
        </SelectItem>
        <SelectItem value="member">
          Member
          {/* <div className="text-xs text-muted-foreground">
            Can do everything except manage team members and billing
          </div> */}
        </SelectItem>
      </SelectContent>
    </Select>
  );
}

function DeleteMemberDialog({ member }: { member: Principal }) {
  const { mutate } = useTeamMemberRemovalMutation();
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Remove Member</DialogTitle>
        <DialogDescription>
          Are you sure you want to remove this member?
        </DialogDescription>
      </DialogHeader>
      <form
        onSubmit={(e) => {
          mutate({ member });
          e.preventDefault();
        }}
      >
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" type="button">
              Cancel
            </Button>
          </DialogClose>
          <Button type="submit">Continue</Button>
        </DialogFooter>
      </form>
    </DialogContent>
  );
}

function ActionsCell({ row }: { row: Row<Member> }) {
  const team = useCurrentTeamQuery();
  const members = useTeamMembersQuery(team.data?.principal);
  const { principal } = useIdp();

  const isAdmin =
    members.data?.find(({ principal: p }) => p.toText() === principal.toText())
      ?.role === "admin";

  const isSelf = row.original.principal.toText() === principal.toText();

  return (
    isAdmin &&
    !isSelf && (
      <div aria-labelledby={`header-3`}>
        <Dialog>
          <DeleteMemberDialog member={row.original.principal} />
          <DialogTrigger asChild>
            <Button variant="outline" size="icon">
              <Trash2Icon size="16" />
            </Button>
          </DialogTrigger>
        </Dialog>
      </div>
    )
  );
}

const columns: ColumnDef<Member>[] = [
  {
    id: "Display Name",
    header: DataTableColumnHeader,
    accessorKey: "displayname",
    cell: ({ row }) => <DisplayNameCell row={row} />,
  },
  { id: "Username", header: DataTableColumnHeader, accessorKey: "username" },
  {
    id: "Principal",
    header: DataTableColumnHeader,
    accessorKey: "id",
    cell: ({ row }) => (
      <PrincipalAbbr>{row.original.principal.toText()}</PrincipalAbbr>
    ),
  },
  {
    id: "Role",
    header: DataTableColumnHeader,
    accessorKey: "role",
    cell: ({ row }) => <RoleCell row={row} />,
  },
  {
    id: "Actions",
    header: DataTableColumnHeader,
    cell: ({ row }) => <ActionsCell row={row} />,
  },
];

function MemberTable() {
  const team = useCurrentTeamQuery();
  const { data, isLoading, isPaused } = useTeamMembersQuery(
    team.data?.principal
  );

  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "Role", desc: false },
  ]);
  const table = useReactTable<Member>({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: { sorting },
    initialState: {},
  });

  if (team.isFetched && !team.data) return <Navigate to="/app/settings" />;

  if (isLoading || isPaused) return <TableSkeleton />;

  return (
    <>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className="group"
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

export function MembersSettings(
  props: MembersSettingsProps & Omit<SettingsProps, "title">
) {
  const team = useCurrentTeamQuery();
  const members = useTeamMembersQuery(team.data?.principal);
  const { principal } = useIdp();

  const isAdmin =
    members.data?.find(({ principal: p }) => p.toText() === principal.toText())
      ?.role === "admin";

  // in storybook you'll need to select a Team first or you'll be given a blank screen
  // TODO mock router in storybook
  if (!team || !principal) return <Navigate to="/app/settings" />;

  return (
    <SettingsPage
      {...props}
      title="Members"
      headChildren={isAdmin && <AddMemberDialog />}
    >
      <Card>
        <MemberTable />
      </Card>
    </SettingsPage>
  );
}

export function ExperimentsSettings() {
  return (
    <SettingsPage title="Experiments">
      <div className="">
        <ExperimentalFeatures />
      </div>
    </SettingsPage>
  );
}

function AddMemberDialog() {
  const { mutateAsync, isPending } = useTeamAddMemberMutation();

  const [open, setOpen] = React.useState(false);

  const [principal, setPrincipal] = React.useState("");
  const [role, setRole] = React.useState<Role>("member");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button>Add Member</Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Member</DialogTitle>
          <DialogDescription>
            To add someone to you team, ask them to sign in and copy their
            principal ID.{" "}
            <a
              href="https://cycleops.notion.site/Joining-or-Adding-Members-to-a-Team-b8847bc0fb0a472687c12dd6098c2d41"
              target="_blank"
            >
              Need help adding team members?
            </a>
          </DialogDescription>
        </DialogHeader>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              await mutateAsync({
                member: Principal.fromText(principal),
                role,
              });
              setOpen(false);
            } catch (err) {
              console.error(err);
              if (err instanceof Error) toast.error(err.message);
              else toast.error("Failed to add member");
            }
          }}
        >
          <div>
            <div className="space-y-4 py-2 pb-4">
              <div className="space-y-2">
                <Label htmlFor="principal">Principal</Label>
                <Input
                  id="principal"
                  value={principal}
                  onChange={(e) => setPrincipal(e.currentTarget.value)}
                  placeholder=""
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="role">Role</Label>
                <Select value={role} onValueChange={(e) => setRole(e as Role)}>
                  <SelectTrigger className="w-40">
                    <SelectValue placeholder="Select a role" />
                  </SelectTrigger>
                  <SelectContent position="popper">
                    <SelectItem value="admin">Admin</SelectItem>
                    <SelectItem value="member">Member</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                Cancel
              </Button>
            </DialogClose>
            <Button type="submit" loading={isPending} disabled={isPending}>
              Continue
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
