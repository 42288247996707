import { LightningBoltIcon } from "@radix-ui/react-icons";
import { format, formatDistanceToNow } from "date-fns";
import { BadgeCent, Infinity, Server, Wallet } from "lucide-react";

import { useCanisterTableQuery } from "@/hooks/queries/canisters";
import { useCustomerPaymentConfQuery } from "@/hooks/queries/customer";
import { useCyclesPriceQuery } from "@/hooks/queries/cycleops-service";
import {
  useCustomerCyclesLedgerAllowanceQuery,
  useCustomerCyclesLedgerBalanceQuery,
} from "@/hooks/queries/ledger-cycles";
import { useCustomerICPBalanceQuery } from "@/hooks/queries/ledger-icp-legacy";

import { TC } from "./tc";
import { TokenAmount } from "./token-amount";
import { Skeleton } from "./ui/skeleton";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

export function AppFooter() {
  return (
    <div className="hidden md:flex justify-between items-center text-xs text-sidebar-foreground/60 pt-2">
      <div className="flex items-center gap-4">
        <WalletBalance />
        <MonitoringInterval />
      </div>
      <div className="flex items-center gap-4 px-2">
        <CyclesPrice />
        <a
          href="https://cycleops.notion.site/Terms-of-Service-CycleOps-4cfb1effd4b34de38df3025bcb9b08c0"
          target="_blank"
        >
          Terms of Service
        </a>
        <img src="/cycleops.png" alt="CycleOps" className="w-6 h-6" />
      </div>
    </div>
  );
}

function WalletBalance() {
  const paymentMethod = useCustomerPaymentConfQuery();

  const method = (() => {
    if (!paymentMethod.data) return null;
    if ("cycles" in paymentMethod.data.paymentMethod) return "cycles";
    if ("icp" in paymentMethod.data.paymentMethod) return "icp";
    return null;
  })();

  if (!paymentMethod.isFetched)
    return (
      <div className="flex items-center gap-1">
        <Wallet className="w-4 h-4" />
        <Skeleton className="h-2 h-4" />
      </div>
    );

  if (method === "icp") return <ICPBalance />;

  if (method === "cycles") return <CyclesBalance />;
}

function ICPBalance() {
  const icpBalance = useCustomerICPBalanceQuery();

  return (
    <Tooltip>
      <TooltipTrigger>
        <div className="flex items-center gap-1">
          <Infinity className="w-4 h-4" strokeWidth={1.5} />
          {icpBalance.isFetched ? (
            <>
              <TokenAmount amount={icpBalance.data?.e8s} stripSymbol /> ICP
            </>
          ) : (
            <Skeleton className="h-2 h-4" />
          )}
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <span className="font-bold">
          <TokenAmount amount={icpBalance.data?.e8s} stripSymbol /> ICP
        </span>{" "}
        in your top-up account.
      </TooltipContent>
    </Tooltip>
  );
}

function CyclesBalance() {
  const cyclesBalance = useCustomerCyclesLedgerBalanceQuery();
  const cyclesAllowance = useCustomerCyclesLedgerAllowanceQuery();

  return (
    <Tooltip>
      <TooltipTrigger>
        <div className="flex items-center gap-1">
          <LightningBoltIcon className="w-4 h-4" strokeWidth={1.5} />
          {cyclesBalance.isFetched && cyclesAllowance.isFetched ? (
            <div className="flex gap-1">
              <span className="">
                <TC
                  value={Number(cyclesAllowance.data?.allowance ?? 0) / 1e12}
                />{" "}
                TC
              </span>
              <span className="text-muted-foreground">
                (<TC value={Number(cyclesBalance.data ?? 0) / 1e12} /> TC)
              </span>
            </div>
          ) : (
            <Skeleton className="h-2 h-4" />
          )}
        </div>
      </TooltipTrigger>
      <TooltipContent className="">
        Wallet balance:{" "}
        <span className="font-bold">
          <TC value={Number(cyclesBalance.data ?? 0) / 1e12} /> TC
        </span>
        . CycleOps allowance:{" "}
        <span className="font-bold">
          <TC value={Number(cyclesAllowance.data?.allowance ?? 0) / 1e12} /> TC
        </span>
        .
      </TooltipContent>
    </Tooltip>
  );
}

function MonitoringInterval() {
  const canisters = useCanisterTableQuery();
  const canister = canisters.data?.[0];
  const lastMonitor = canister?.lastBalanceCheck;
  const nextMonitor = canister?.nextBalanceCheck;

  if (!lastMonitor || !nextMonitor) return null;

  return (
    <Tooltip>
      <TooltipTrigger>
        <div className="flex items-center gap-1">
          <Server className="w-4 h-4" strokeWidth={1.5} />
          <span>Last Monitor {format(lastMonitor, "MMM d h:mma")}</span>
          <span className="text-muted">/</span>
          <span>Next Monitor {formatDistanceToNow(nextMonitor)}</span>
        </div>
      </TooltipTrigger>
      <TooltipContent>
        CycleOps monitors and tops up your canisters every 6 hours.
      </TooltipContent>
    </Tooltip>
  );
}

function CyclesPrice() {
  const cyclesPrice = useCyclesPriceQuery();
  return (
    <Tooltip>
      <TooltipTrigger>
        <div className="flex items-center gap-1">
          ${cyclesPrice.data?.usdPerTrillionCycles?.toFixed(3)} Per TC{" "}
          <span>
            (
            <TokenAmount
              amount={cyclesPrice.data?.icpPerTrillionCycles.e8s}
              stripSymbol
            />{" "}
            ICP)
          </span>
        </div>
      </TooltipTrigger>
      <TooltipContent>
        Price at which CycleOps is selling cycles.
      </TooltipContent>
    </Tooltip>
  );
}
