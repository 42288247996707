import { Principal } from "@dfinity/principal";
import { Box, Settings } from "lucide-react";

import { useCanisterProject } from "@/hooks/queries/canisters";
import {
  useBatchCanisterProjectMutation,
  useProjectsQuery,
} from "@/hooks/queries/projects";
import { AppLink } from "@/hooks/queries/team";

import { Project } from "./canister-list/table/cells";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuSeparator,
  DropdownMenuItem,
} from "./ui/dropdown-menu";

export function EditableCanisterProject({
  canisterId,
}: {
  canisterId: Principal;
}) {
  const existingProjects = useProjectsQuery();
  const canisterProject = useCanisterProject(canisterId);
  const mutation = useBatchCanisterProjectMutation();

  function handleProject(project: string) {
    mutation.mutate({
      canisterIds: [canisterId],
      projectName: project,
    });
  }

  const projects = existingProjects.data?.map((project) => {
    const checked = !!canisterProject.data?.includes(project.name);
    return { project: project.name, checked };
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="cursor-pointer">
          <Project value={canisterProject.data} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        {projects?.map(({ project, checked }) => (
          <DropdownMenuCheckboxItem
            key={project}
            checked={checked}
            onSelect={(e) => {
              handleProject(project);
              e.preventDefault();
            }}
            className="gap-2 items-center"
          >
            {project}
          </DropdownMenuCheckboxItem>
        ))}
        {!!projects?.length && <DropdownMenuSeparator />}
        <DropdownMenuItem asChild>
          <AppLink to="/settings/projects" className="gap-2">
            <Settings className="w-4 h-4" />
            Manage Projects
          </AppLink>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
