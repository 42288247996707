import { Principal } from "@dfinity/principal";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useRef } from "react";

import { useKeyboardNavigation } from "@/components/canister-list/table/keyboard-navigation";
import { MemoizedDataTableRow } from "@/components/canister-list/table/row";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { TooltipProvider } from "@/components/ui/tooltip";
import { useCanisterTableQuery } from "@/hooks/queries/canisters";
import { AppLink } from "@/hooks/queries/team";
import { useCanisterTable } from "@/hooks/stores/canister-table-store";
import { cn } from "@/lib/ui-utils";

interface DataTableProps {
  className?: string;
  onRowClick?: (id: Principal) => void;
}

export function DataTable({ className, onRowClick }: DataTableProps) {
  const table = useCanisterTable();
  const query = useCanisterTableQuery();
  const { highlightedRowIndex, setHighlightedRowIndex } =
    useKeyboardNavigation();

  const { rows } = table.getRowModel();
  const parentRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 48,
    overscan: 20,
  });

  return (
    <TooltipProvider disableHoverableContent={false}>
      <div
        className={cn("h-full overflow-y-auto overflow-x-hidden", className)}
        ref={parentRef}
        onMouseLeave={() => setHighlightedRowIndex(-1)}
      >
        <div style={{ height: `${virtualizer.getTotalSize()}px` }}>
          <div>
            <div>
              {!query.isFetched ? (
                Array(10)
                  .fill(0)
                  .map((_, i) => (
                    <div key={`table-skeleton-${i}`} className="w-full">
                      <div className="px-1 py-0 h-12 flex items-center">
                        <Skeleton className="w-full h-4" />
                      </div>
                    </div>
                  ))
              ) : table.getRowModel().rows?.length ? (
                virtualizer.getVirtualItems().map((virtualRow, index) => {
                  const row = rows[virtualRow.index]!;
                  return (
                    <div
                      key={row.id}
                      style={{
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${
                          virtualRow.start - index * virtualRow.size
                        }px)`,
                      }}
                    >
                      <MemoizedDataTableRow
                        cells={row.getVisibleCells()}
                        key={row.id}
                        onRowClick={onRowClick}
                        isSelected={row.getIsSelected()}
                        isHighlighted={virtualRow.index === highlightedRowIndex}
                        onHover={() => setHighlightedRowIndex(virtualRow.index)}
                        canisterId={row.original.id}
                      />
                    </div>
                  );
                })
              ) : (
                <EmptyState />
              )}
            </div>
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
}

function EmptyState() {
  return (
    <div className="w-full h-[200px] flex flex-col items-center justify-center gap-5">
      You haven't added any canisters yet.
      <AppLink to="canisters/new">
        <Button>Add a canister</Button>
      </AppLink>
    </div>
  );
}
