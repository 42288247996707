import { useMemo } from "react";

import {
  useCustomerNotificationSettingsQuery,
  useCustomerPaymentConfQuery,
} from "@/hooks/queries/customer";
import { useCustomerCyclesLedgerAllowanceQuery } from "@/hooks/queries/ledger-cycles";
import { useCustomerICPBalanceQuery } from "@/hooks/queries/ledger-icp-legacy";
import { AppLink } from "@/hooks/queries/team";
import { cn } from "@/lib/ui-utils";

import { TC } from "./tc";
import { TokenAmount } from "./token-amount";
import { Alert } from "./ui/alert";
import { SidebarGroup, SidebarGroupContent } from "./ui/sidebar";

export function AppNotifications() {
  return (
    <SidebarGroup className="mt-auto">
      <SidebarGroupContent>
        <LowBalanceNotifications />
      </SidebarGroupContent>
    </SidebarGroup>
  );
}

function LowBalanceNotifications() {
  const icpBalanceNotification = useCustomerNotificationSettingsQuery();
  const icpBalance = useCustomerICPBalanceQuery();
  const cyclesAllowance = useCustomerCyclesLedgerAllowanceQuery();
  const paymentConf = useCustomerPaymentConfQuery();

  const paymentMethod = paymentConf.data
    ? "icp" in paymentConf.data.paymentMethod
      ? "icp"
      : "cycles"
    : undefined;

  const low = useMemo(() => {
    if (!icpBalanceNotification.data) return { icp: false, cycles: false };
    const { notifyOnICPBelow, notifyOnCyclesApprovalBalanceBelow } =
      icpBalanceNotification.data;

    const icp = (() => {
      if (!notifyOnICPBelow) return false;
      if (!icpBalance.data) return false;
      if (icpBalance.data.e8s < notifyOnICPBelow.e8s) return true;
      return false;
    })();

    const cycles = (() => {
      if (!notifyOnCyclesApprovalBalanceBelow) return false;
      if (!cyclesAllowance.data) return false;
      if (cyclesAllowance.data.allowance < notifyOnCyclesApprovalBalanceBelow)
        return true;
      return false;
    })();

    return {
      icp,
      cycles,
    };
  }, [icpBalanceNotification.data, icpBalance.data, cyclesAllowance.data]);

  if (low.icp && paymentMethod === "icp") return <LowICP />;
  if (low.cycles && paymentMethod === "cycles") return <LowCycles />;
  return null;
}

function LowCycles() {
  const cyclesAllowance = useCustomerCyclesLedgerAllowanceQuery();
  const notificationSettings = useCustomerNotificationSettingsQuery();

  return (
    <AppNotification className="bg-orange-600/5 border-orange-400/10">
      <AppNotificationTitle>Low Cycles</AppNotificationTitle>
      <AppNotificationDescription>
        Your cycles allowance (
        <TC value={Number(cyclesAllowance.data?.allowance ?? 0) / 1e12} /> TC)
        is below your alert threshold (
        <TC
          value={
            Number(
              notificationSettings.data?.notifyOnCyclesApprovalBalanceBelow ?? 0
            ) / 1e12
          }
        />{" "}
        TC).
      </AppNotificationDescription>
      <AppNotificationLink>
        <AppLink to="/billing/payment-method">Manage Payment Method</AppLink>
      </AppNotificationLink>
    </AppNotification>
  );
}

function LowICP() {
  const icpBalance = useCustomerICPBalanceQuery();
  const notificationSettings = useCustomerNotificationSettingsQuery();

  return (
    <AppNotification className="bg-orange-600/10 border-orange-400/25">
      <AppNotificationTitle>Low ICP</AppNotificationTitle>
      <AppNotificationDescription>
        Your ICP balance (
        <TokenAmount amount={icpBalance.data?.e8s ?? 0n} stripSymbol /> ICP) is
        below your alert threshold (
        <TokenAmount
          amount={notificationSettings.data?.notifyOnICPBelow?.e8s ?? 0n}
          stripSymbol
        />{" "}
        ICP).
      </AppNotificationDescription>
      <AppNotificationLink>
        <AppLink to="/billing/payment-method">Manage Payment Method</AppLink>
      </AppNotificationLink>
    </AppNotification>
  );
}

function AppNotification({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <Alert className={cn("flex flex-col gap-2 p-3 text-xs", className)}>
      {children}
    </Alert>
  );
}

function AppNotificationTitle({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return <div className={cn("text-xs font-bold", className)}>{children}</div>;
}

function AppNotificationDescription({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return <div className={cn("text-xs", className)}>{children}</div>;
}

function AppNotificationLink({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return <div className={cn("text-xs underline", className)}>{children}</div>;
}
