import { Principal } from "@dfinity/principal";
import { useCallback, useState, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { SelectedCanisterCommandPrompt } from "@/components/canister-list/commands";
import { DataTable } from "@/components/canister-list/table/table";
import CanisterSheet from "@/components/canister-sheet";
import { useRoute } from "@/hooks/queries/team";
import { cn } from "@/lib/ui-utils";

export default function CanisterTable({ className }: { className?: string }) {
  const { canisterId: routeCanisterId } = useParams();
  const navigate = useNavigate();
  const route = useRoute();
  const [canisterId, setCanisterId] = useState<Principal>();
  const [open, setOpen] = useState(!!routeCanisterId);

  const onRowClick = useCallback(
    (id: Principal) => {
      setCanisterId(id);
      navigate(route(`/canisters/detail/${id}`));
    },
    [open]
  );

  useEffect(() => {
    setOpen(!!routeCanisterId);
  }, [routeCanisterId]);

  useEffect(() => {
    if (!open) {
      navigate(route(`/`));
    }
  }, [open]);

  return (
    <>
      <CanisterSheet open={open} setOpen={setOpen} canisterId={canisterId}>
        <Outlet />
      </CanisterSheet>
      <div className={cn("relative bg-table h-[100dvh]", className)}>
        <DataTable className="" onRowClick={onRowClick} />
      </div>
      <SelectedCanisterCommandPrompt className="absolute bottom-0" />
    </>
  );
}
