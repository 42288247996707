import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  FilterFn,
  RowSelectionState,
  SortingState,
  Table,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import { FilterValue } from "@/components/canister-list/filters/types";
import { columns } from "@/components/canister-list/table/columns";
import { useCanisterTableQuery } from "@/hooks/queries/canisters";
import { CanisterTableData } from "@/lib/insights/canister-insights";

interface CanisterTableStore {
  table: Table<CanisterTableData>;
  setTable: (x: Table<CanisterTableData>) => void;
  sorting: SortingState;
  setSorting: (x: SortingState) => void;
  rowSelection: RowSelectionState;
  setRowSelection: (x: RowSelectionState) => void;
  columnVisibility: VisibilityState;
  setColumnVisibility: (x: VisibilityState) => void;
  filters: FilterValue[];
  setFilters: (x: FilterValue[]) => void;
}

export const useCanisterTableStore = create<CanisterTableStore>()(
  persist(
    (set) => ({
      table: {} as Table<CanisterTableData>,
      setTable: (table) => set({ table }),
      sorting: [{ id: "Status", desc: true }],
      setSorting: (sorting) => set({ sorting }),
      rowSelection: {},
      setRowSelection: (rowSelection) => set({ rowSelection }),
      columnVisibility: {},
      setColumnVisibility: (columnVisibility) => set({ columnVisibility }),
      filters: [],
      setFilters: (filters) => set({ filters }),
    }),
    {
      name: "canister-table-state",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => {
        const store = {
          sorting: state.sorting,
          columnVisibility: state.columnVisibility,
        };
        return store;
      },
    }
  )
);

function updater<T extends object>(set: (d: T) => void, old: T) {
  return (u: T | ((old: T) => T)) => {
    if (typeof u === "function") {
      set(u(old));
    } else {
      set(u);
    }
  };
}

const customFilterFn: FilterFn<any> = (
  row,
  columnId,
  filterValue: FilterValue
) => {
  const value = row.getValue(columnId);

  switch (filterValue.type) {
    case "select":
      return filterValue.comparator === "is"
        ? value === filterValue.value
        : value !== filterValue.value;

    case "string":
      const strValue = String(value || "");
      return filterValue.comparator === "is"
        ? strValue.includes(filterValue.value?.toString() || "")
        : !strValue.includes(filterValue.value?.toString() || "");

    case "number":
      const numValue = Number(value);
      let filterNum = Number(filterValue.value);

      if (filterValue.field === "Memory Size") {
        filterNum = filterNum * 1024 * 1024;
      }

      if (filterValue.field === "Balance") {
        filterNum *= 10e12;
      }

      if (
        filterValue.field === "Burn (30d)" ||
        filterValue.field === "Burn (24hr)"
      )
        filterNum *= 10e11;

      switch (filterValue.comparator) {
        case "equals":
          return numValue === filterNum;
        case "greater than":
          return numValue > filterNum;
        case "less than":
          return numValue < filterNum;
        default:
          return true;
      }

    default:
      return false;
  }
};

function useCanisterTable() {
  const canisters = useCanisterTableQuery();
  const data = useMemo(() => canisters.data ?? [], [canisters.data]);
  const { filters, setFilters } = useCanisterTableStore();

  // Convert filters to TanStack format
  const columnFilters = useMemo(
    () =>
      filters.map((filter) => ({
        id: filter.field,
        value: filter,
      })),
    [filters]
  );

  const {
    sorting,
    setSorting,
    rowSelection,
    setRowSelection,
    columnVisibility,
    setColumnVisibility,
    setTable,
  } = useCanisterTableStore();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    filterFns: {
      custom: customFilterFn,
    },
    onRowSelectionChange: updater(setRowSelection, rowSelection),
    onSortingChange: updater(setSorting, sorting),
    onColumnVisibilityChange: updater(setColumnVisibility, columnVisibility),
    state: {
      sorting,
      rowSelection,
      columnVisibility,
      columnFilters,
      pagination: {
        pageSize: 10_000,
        pageIndex: 0,
      },
    },
    autoResetPageIndex: false,
  });

  useEffect(() => {
    setTable(table);
  }, []);

  useEffect(() => {
    if (columnVisibility && Object.keys(columnVisibility).length > 0) return;
    setColumnVisibility(
      table.getAllFlatColumns().reduce((acc, x) => {
        if (!x.id) return acc;
        const visible = x.columnDef.meta?.defaultHidden !== true;
        acc[x.id] = visible;
        return acc;
      }, {} as Record<string, boolean>)
    );
  }, []);

  return table;
}

export { useCanisterTable };
