import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface SlotContextValue {
  slots: Record<string, React.ReactNode>;
  registerSlot: (name: string, content: React.ReactNode) => void;
}

const SlotContext = createContext<SlotContextValue | null>(null);

export function SlotProvider({ children }: { children: React.ReactNode }) {
  const [slots, setSlots] = useState<Record<string, React.ReactNode>>({});

  const registerSlot = useCallback((name: string, content: React.ReactNode) => {
    setSlots((prev) => {
      if (prev[name] === content) return prev;
      return { ...prev, [name]: content };
    });
  }, []);

  return (
    <SlotContext.Provider value={{ slots, registerSlot }}>
      {children}
    </SlotContext.Provider>
  );
}

export function useSlots() {
  const context = useContext(SlotContext);
  if (!context) {
    throw new Error("useSlots must be used within a SlotProvider");
  }
  return context;
}

export function Slot({
  name,
  children,
}: {
  name: string;
  children: React.ReactNode;
}) {
  const { registerSlot } = useSlots();

  useEffect(() => {
    registerSlot(name, children);
  }, [name, children, registerSlot]);

  return null;
}

export function SlotOutlet({ name }: { name: string }) {
  const { slots } = useSlots();
  return <>{slots[name]}</>;
}
