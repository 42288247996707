import { Principal } from "@dfinity/principal";
import { differenceInDays, format, formatDuration } from "date-fns";
import { useMemo } from "react";
import {
  Brush,
  CartesianGrid,
  TooltipProps,
  XAxis,
  YAxis,
  ComposedChart,
  Bar,
  ReferenceArea,
} from "recharts";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useCanisterTableDetailQuery } from "@/hooks/queries/canisters";
import { useCanisterQueryCallsInsight } from "@/hooks/queries/insights";
import { useTimeSettingsStore } from "@/state/stores/time-settings";

import { ChartConfig, ChartContainer, ChartTooltip } from "../ui/chart";
import { ChartBrushTraveller } from "./ChartBrushTraveller";
import { ChartFigure } from "./burn";
import { TimeSpanString } from "./shared";
import { useChartBrush } from "./useChartBrush";

function generateQueryCallsData() {
  const modes = {
    hi: { min: 1000, max: 5000 }, // High traffic: 1k-5k calls
    lo: { min: 10, max: 100 }, // Low traffic: 10-100 calls
  };

  const modeKeys = Object.keys(modes) as Array<keyof typeof modes>;
  const selectedMode = modeKeys[Math.floor(Math.random() * modeKeys.length)]!;
  const { min, max } = modes[selectedMode];

  const data = [];
  const now = new Date();
  const startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days ago
  const periodDuration = 1000 * 60 * 60 * 6; // 6 hours in milliseconds

  // Generate regular data
  for (let i = 0; i < 30 * 4; i++) {
    const timestamp = new Date(startDate.getTime() + i * 6 * 60 * 60 * 1000);
    const amount = Math.floor(Math.random() * (max - min) + min);
    data.push({
      timestamp,
      amount,
      periodDuration,
    });
  }

  return data;
}

interface QueryCallData {
  timestamp: Date;
  amount: number;
  periodDuration: number;
}

function CustomTooltip({
  active,
  payload,
  label,
}: TooltipProps<number, string>) {
  const data = payload?.[0]?.payload as QueryCallData | undefined;

  if (!data) return null;

  return (
    <div className="min-w-[8rem] rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl flex flex-col">
      <div className="font-medium mb-1">Query Calls</div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Time</div>
        <div className="font-mono">
          {data.timestamp && format(data.timestamp, "MMM d, HH:mm")}
        </div>
      </div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Calls This Period</div>
        <div className="font-mono">{data.amount} calls</div>
      </div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Period Duration</div>
        <div className="">
          {data.periodDuration &&
            formatDuration({
              hours: Math.round(data.periodDuration / 60 / 60 / 1000),
            })}
        </div>
      </div>
    </div>
  );
}

export function QueryCallsChart({ canisterId }: { canisterId?: Principal }) {
  const timeSettings = useTimeSettingsStore();
  const insight = useCanisterQueryCallsInsight(canisterId, timeSettings);
  const canister = useCanisterTableDetailQuery(canisterId);

  // Mock data for now - would be replaced with actual data hook
  // const chartData = useMemo(() => generateQueryCallsData(), []);

  const chartData = useMemo(
    () =>
      insight.points.map(([timestamp, data]) => ({
        timestamp,
        amount: Number(data),
      })),
    [insight]
  );

  const chartConfig = {
    amount: {
      label: "Query Calls",
      color: "hsl(var(--foreground))",
    },
  } satisfies ChartConfig;

  // State for brush selection with new hook state
  const {
    brushProps,
    brushStartIndex,
    brushEndIndex,
    startDate,
    endDate,
    formatString,
  } = useChartBrush({
    data: chartData,
  });

  // Conditional tick formatter using formatString from hook
  const tickFormatter = (value: string) => {
    const date = new Date(value);
    return format(date, formatString);
  };

  // Total calls for the selected period (including the brush)
  const totalCalls = Number(canister.data?.queryCallsTotal || 0);

  const lastTimestamp = chartData?.[chartData.length - 1]?.timestamp;

  return (
    <Card className="rounded-sm pb-3 bg-transparent">
      <CardHeader className="px-3 py-3 gap-2">
        <CardTitle className="text-sm">
          Query Calls{" "}
          <TimeSpanString
            startDate={startDate}
            endDate={endDate}
            formatString={formatString}
          />
        </CardTitle>
        {chartData.length > 0 && (
          <ChartFigure
            main={`${totalCalls.toLocaleString()} calls`}
            subheader={
              lastTimestamp ? format(lastTimestamp, "MMM dd HH:mm") : "N/A"
            }
          />
        )}
      </CardHeader>
      <CardContent className="px-2 py-2">
        <ChartContainer config={chartConfig} className="h-[200px] w-full">
          <ComposedChart
            accessibilityLayer
            data={chartData}
            margin={{
              left: 20,
              right: 0,
              top: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="timestamp"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={tickFormatter}
            />
            <YAxis
              yAxisId="right"
              key="amount"
              tickLine={true}
              axisLine={false}
              unit=" calls"
              orientation="right"
            />
            <ChartTooltip
              cursor={{
                strokeWidth: 2,
              }}
              content={<CustomTooltip />}
            />
            <Bar
              yAxisId="right"
              dataKey="amount"
              barSize={20}
              fill="hsl(var(--foreground))"
            />
            <Brush {...brushProps} traveller={<ChartBrushTraveller />} />
          </ComposedChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
