import { Navigate } from "react-router-dom";

import { AddMemberDialog, MemberTable } from "@/components/pages/settings";
import { SiteBreadcrumb } from "@/components/site-breadcrumb";
import { useCurrentTeamQuery, useTeamMembersQuery } from "@/hooks/queries/team";
import { useIdp } from "@/state/stores/idp";

import { Layout, TopBar } from "./layout";

export default function MembersPage() {
  const team = useCurrentTeamQuery();
  const members = useTeamMembersQuery(team.data?.principal);
  const { principal } = useIdp();

  const isAdmin =
    members.data?.find(({ principal: p }) => p.toText() === principal.toText())
      ?.role === "admin";

  // in storybook you'll need to select a Team first or you'll be given a blank screen
  // TODO mock router in storybook
  if (!team || !principal) return <Navigate to="/app/settings" />;

  return (
    <Layout>
      <TopBar>
        <SiteBreadcrumb
          items={[
            {
              title: "Settings",
              href: "/settings",
            },
            {
              title: "Members",
            },
          ]}
        />
        <div className="flex items-center gap-2">
          {isAdmin && <AddMemberDialog />}
        </div>
      </TopBar>
      <div className="border border-border/75 flex flex-1 flex-col gap-4 pt-0 relative rounded-lg overflow-hidden bg-table">
        <div className="h-auto">
          <MemberTable />
        </div>
      </div>
    </Layout>
  );
}
