import { create } from "zustand";

interface CreateProjectDialogStore {
  isOpen: boolean;
  projectName: string;
  open: () => void;
  close: () => void;
  setProjectName: (name: string) => void;
  reset: () => void;
}

export const useCreateProjectDialogStore = create<CreateProjectDialogStore>(
  (set) => ({
    isOpen: false,
    projectName: "",
    open: () => set({ isOpen: true }),
    close: () => set({ isOpen: false }),
    setProjectName: (name: string) => set({ projectName: name }),
    reset: () => set({ projectName: "", isOpen: false }),
  })
);
