import { Principal } from "@dfinity/principal";
import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { ZodError } from "zod";

import {
  MonitoringCanisterType,
  TopupRule,
} from "common/declarations/cycleops/cycleops.did.d";

import InputAffix from "@/components/input-affix";
import LabelGrid from "@/components/label-grid";
import { mostRecentCycleBalanceFromStatusHistory } from "@/components/pages/canister-list";
import { Status } from "@/components/status-indicator";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import {
  UseCanistersResult,
  AddCanisterRequest,
  useCanisterTopupRuleMutation,
  useCanistersQuery,
} from "@/hooks/queries/canisters";
import { AddCanisterSchema, PrincipalError } from "@/state/forms/onboarding";

import { useFindCanisterOr404 } from "./pages/canister-detail";

export interface Props {
  action: (request: AddCanisterRequest) => void;
  loading?: boolean;
  canister?: Canister;
}

export interface Canister {
  id: Principal;
  name?: string;
  rule: TopupRule;
  cycles?: {
    status: Status;
    balance: number;
    timestamp: Date;
  };
  monitoringType?: "blackhole" | "sns" | "nns" | "publicStatus";
}

export function mapMonitoringType(mtype: MonitoringCanisterType) {
  if (mtype === undefined) return undefined;
  if ("blackhole" in mtype) return "blackhole";
  if ("sns" in mtype) return "sns";
  if ("nns" in mtype) return "nns";
  if ("publicStatus" in mtype) return "publicStatus";
  throw new Error("Unknown monitoring type");
}

export function reduceCanister(
  state: UseCanistersResult,
  canisterId: string
): Canister | undefined {
  const canister = state.data?.find(([id]) => id.toText() === canisterId);
  if (!canister) return undefined;
  const [id, conf, statusRecords, , mtype] = canister;
  return {
    id,
    name: conf.name,
    rule: conf.topupRule,
    cycles: mostRecentCycleBalanceFromStatusHistory(statusRecords, conf),
    monitoringType: mapMonitoringType(mtype),
  };
}

export default function CanisterRule() {
  const { canisterId } = useParams();
  useFindCanisterOr404(canisterId);

  const { mutate, isPending } = useCanisterTopupRuleMutation();
  const canisters = useCanistersQuery();
  const canister = reduceCanister(canisters, canisterId!);

  const [threshold, setThreshold] = React.useState<string>();
  const [amount, setAmount] = React.useState<string>();
  React.useEffect(() => {
    setAmount(
      `${
        canister
          ? "by_amount" in canister.rule.method
            ? Number(canister.rule.method.by_amount) / 1e12
            : Number(canister.rule.method.to_balance) / 1e12
          : 3
      }`
    );
  }, [canister?.rule.method]);
  React.useEffect(() => {
    setThreshold(`${canister ? Number(canister.rule.threshold) / 1e12 : 1}`);
  }, [canister?.rule.threshold]);
  const method = "by_amount";
  const onSubmit = React.useCallback<React.FormEventHandler>(
    (event) => {
      event.preventDefault();
      if (isPending) return;
      try {
        const result = AddCanisterSchema.parse({
          canisterId: canister?.id.toText(),
          name: canister?.name,
          threshold,
          amount,
          method,
        });
        mutate(result);
      } catch (error) {
        if (error instanceof PrincipalError) {
          toast.error("Canister ID is not a valid principal");
        } else if (error instanceof ZodError) {
          for (const err of error.issues) {
            toast.error(err.message);
          }
        }
      }
    },
    [mutate, canister?.id, threshold, amount, method, isPending]
  );
  return (
    <div className={"pb-4"}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <form onSubmit={onSubmit}>
          <Card>
            <CardHeader>
              <CardTitle>Rule</CardTitle>
              <CardDescription>
                These parameters allow you to determine when to send more cycles
                to this canister.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className={"flex flex-col gap-5"}>
                <LabelGrid
                  rows={[
                    {
                      label: "Threshold",
                      content: (
                        <InputAffix affix="Trillion Cycles">
                          <Input
                            id="threshold"
                            value={threshold}
                            className="rounded-r-none"
                            onChange={(e) =>
                              setThreshold(e.currentTarget.value)
                            }
                          />
                        </InputAffix>
                      ),
                      id: "threshold",
                    },
                    {
                      label: "Amount",
                      content: (
                        <InputAffix affix="Trillion Cycles">
                          <Input
                            className="rounded-r-none"
                            id="amount"
                            value={amount}
                            onChange={(e) => setAmount(e.currentTarget.value)}
                          />
                        </InputAffix>
                      ),
                      id: "amount",
                    },
                  ]}
                />
              </div>
            </CardContent>
            <CardFooter className={"flex justify-between items-center w-full"}>
              <small>
                Learn more about{" "}
                <a
                  href="https://docs.cycleops.dev/docs/basics/topup-rules"
                  target="_blank"
                >
                  best practices for for top-up rules
                </a>
              </small>
              <Button type="submit" loading={isPending}>
                Save
              </Button>
            </CardFooter>
          </Card>
        </form>
      </div>
    </div>
  );
}
