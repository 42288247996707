import { Check, Minus, Tag } from "lucide-react";
import { useState } from "react";
import { create } from "zustand";

import {
  useAllCanistersTags,
  useCanistersTags,
} from "@/hooks/queries/canisters";
import { useBatchCanisterTagMutation } from "@/hooks/queries/projects";
import { cn } from "@/lib/ui-utils";

import { Button } from "../../ui/button";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../ui/command";
import { useSelectedCanisters } from "../table/context-menu";

interface TagCommandState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export const useTagCommand = create<TagCommandState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
}));

export function TagCommandDialog() {
  const { isOpen, close } = useTagCommand();
  const selectedCanisters = useSelectedCanisters();
  const existingTags = useAllCanistersTags();
  const selectionTags = useCanistersTags(selectedCanisters);
  const mutation = useBatchCanisterTagMutation();
  const [inputValue, setInputValue] = useState("");

  function handleTags(tag: string, type: "add" | "remove") {
    mutation.mutate({
      canisterIds: selectedCanisters,
      tag,
      type,
    });
  }

  function handleNewTag(value: string) {
    if (!value.trim()) return;
    handleTags(value, "add");
    setInputValue("");
  }

  const tags = existingTags.data
    ?.map((tag) => {
      if (!tag) return null;
      const checked = selectionTags.data?.[tag];
      return { tag, checked };
    })
    .filter(
      (tag): tag is { tag: string; checked: boolean | "indeterminate" } => !!tag
    );

  return (
    <CommandDialog open={isOpen} onOpenChange={close}>
      <div className="flex justify-start px-3 py-3">
        <div className="px-3 py-2 bg-muted/50 rounded-md">
          {selectedCanisters.length} Canisters
        </div>
      </div>
      <CommandInput
        placeholder="Search or create tags..."
        value={inputValue}
        onValueChange={setInputValue}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleNewTag(inputValue);
          }
        }}
      />
      <CommandList>
        <CommandEmpty>
          <div className="p-4 flex flex-col gap-2">
            <div className="text-sm text-muted-foreground">
              No matching tags found
            </div>
            {inputValue && (
              <Button
                variant="secondary"
                className="gap-2"
                onClick={() => handleNewTag(inputValue)}
              >
                <Tag className="w-4 h-4" />
                Create tag "{inputValue}"
              </Button>
            )}
          </div>
        </CommandEmpty>
        <CommandGroup>
          {tags?.map(({ tag, checked }) => (
            <CommandItem
              key={tag}
              onSelect={() =>
                handleTags(tag, checked === true ? "remove" : "add")
              }
              className="gap-2 items-center"
            >
              <div className="flex items-center gap-2 flex-1">
                <div
                  className={cn(
                    "h-4 w-4 border rounded-sm flex items-center justify-center",
                    (checked === true || checked === "indeterminate") &&
                      "bg-muted/50 border-border"
                  )}
                >
                  {checked === true && <Check className="w-3 h-3" />}
                  {checked === "indeterminate" && <Minus className="w-3 h-3" />}
                </div>
                {tag}
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  );
}
