import { Principal } from "@dfinity/principal";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import { CanisterSheetMenu } from "@/components/canister-sheet";
import ManualTopUpCanister from "@/components/manual-top-up-canister";
import { BreadcrumbSeparator } from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Slot } from "@/components/ui/slot-context";
import { useCanisterTableDetailQuery } from "@/hooks/queries/canisters";
import { useCustomerICPBalanceQuery } from "@/hooks/queries/ledger-icp-legacy";
import { useRoute } from "@/hooks/queries/team";

import { CanisterBreadcrumb } from "./canister-breadcrumb";
import { Layout, TopBar } from "./layout";

export default function CanisterDetailPage() {
  const { canisterId: canisterIdString } = useParams();
  const canisterId = canisterIdString
    ? Principal.fromText(canisterIdString)
    : undefined;

  useCanister404Redirect();

  return (
    <Layout>
      <Slot name="breadcrumb">
        <BreadcrumbSeparator />
      </Slot>
      <TopBar>
        <div className="flex items-center gap-2">
          <CanisterBreadcrumb canisterId={canisterId} />
          <CanisterSheetMenu detail />
        </div>
        <div className="flex-1 flex justify-end">
          <TopUpButton />
        </div>
      </TopBar>
      <div className="border border-border/75 flex flex-1 flex-col gap-4 p-2 md:p-4 pt-0 relative bg-table rounded-lg overflow-hidden">
        <div className="h-auto absolute inset-0 overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </Layout>
  );
}

function useCanister404Redirect() {
  const { canisterId: canisterIdString } = useParams();
  const canisterId = canisterIdString
    ? Principal.fromText(canisterIdString)
    : undefined;

  const route = useRoute();
  const navigate = useNavigate();

  const canister = useCanisterTableDetailQuery(canisterId);

  useEffect(() => {
    if (canister.isLoading) return;
    if (!canister.data) {
      toast.info("Canister not found in this account", { id: "canister-404" });
      navigate(route("/canisters"), { replace: true });
    }
  }, [canister.isLoading, canister.data]);

  return null;
}

function TopUpButton() {
  const { canisterId: canisterIdString } = useParams();
  const canisterId = canisterIdString
    ? Principal.fromText(canisterIdString)
    : undefined;

  const canister = useCanisterTableDetailQuery(canisterId);
  const ICPBalance = useCustomerICPBalanceQuery().data;

  return (
    <ManualTopUpCanister
      canister={{
        name: canister.data?.name ?? "",
        id: canisterId ?? Principal.anonymous(),
        status: canister.data?.status ?? "pending",
        cycles: { trillions: Number(canister.data?.balance ?? 0) },
      }}
      ICPBalance={ICPBalance && { e8s: Number(ICPBalance.e8s) }}
    >
      <Button size="sm" className="text-xs">
        Top Up Now
      </Button>
    </ManualTopUpCanister>
  );
}
