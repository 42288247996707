import {
  Filter,
  FolderIcon,
  FuelIcon,
  HeartPulseIcon,
  MemoryStickIcon,
  WalletIcon,
} from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useCanisterTableQuery } from "@/hooks/queries/canisters";
import { useCanisterTableStore } from "@/hooks/stores/canister-table-store";

import { FILTER_COMPARATORS } from "./constants";
import { FilterItem } from "./filter-item";
import { FilterComponent } from "./selected-filter";
import { FilterValue, CanisterTableFilters } from "./types";

interface CanisterListFiltersProps {
  className?: string;
  children?: React.ReactNode;
}

export function CanisterListFilters({
  className,
  children,
}: CanisterListFiltersProps) {
  const { filters: selectedFilters, setFilters: setSelectedFilters } =
    useCanisterTableStore();
  const [activeFilter, setActiveFilter] = useState<FilterValue | null>(null);
  const [open, setOpen] = useState(false);
  const canisters = useCanisterTableQuery();

  const uniqueProjects = useMemo(() => {
    if (!canisters.data) return [];
    return Array.from(
      new Set(canisters.data.map((item) => item.project))
    ).filter(Boolean) as string[];
  }, [canisters.data]);

  const canisterFilter: CanisterTableFilters = useMemo(
    () => ({
      project: {
        label: "Project",
        field: "Project",
        comparator: "is",
        type: "select",
        options: uniqueProjects,
        icon: FolderIcon,
      },
      status: {
        label: "Status",
        field: "Status",
        comparator: "is",
        type: "select",
        options: ["pending", "healthy", "unhealthy", "frozen"],
        icon: HeartPulseIcon,
      },
      memorySize: {
        label: "Memory Size",
        field: "Memory Size",
        comparator: "greater than",
        type: "number",
        options: [10],
        icon: MemoryStickIcon,
      },
      balance: {
        label: "Cycles balance",
        field: "Balance",
        comparator: "less than",
        type: "number",
        options: [5],
        icon: WalletIcon,
      },
      burnPerDay: {
        label: "Burned (24 hours)",
        field: "Burn (24hr)",
        comparator: "greater than",
        type: "number",
        options: [1],
        icon: FuelIcon,
      },
      burnTotal: {
        label: "Burned (30 days)",
        field: "Burn (30d)",
        comparator: "greater than",
        type: "number",
        options: [1],
        icon: FuelIcon,
      },
    }),
    [uniqueProjects]
  );

  const trigger = children ?? (
    <Button size="sm" variant="ghost" className="gap-2 bg-muted/0">
      <Filter className="w-4 h-4" />
    </Button>
  );

  const handleFilterChange = (index: number, updatedFilter: FilterValue) => {
    const updatedFilters = [...selectedFilters];
    updatedFilters[index] = updatedFilter;

    setSelectedFilters(updatedFilters);
  };

  return (
    <div className="flex gap-4 items-center">
      <DropdownMenu
        open={open}
        onOpenChange={(value) => {
          setOpen(value);
          if (!value) setActiveFilter(null);
        }}
      >
        <DropdownMenuTrigger asChild>{trigger}</DropdownMenuTrigger>
        {!activeFilter ? (
          <DropdownMenuContent align="start" className="w-52">
            <DropdownMenuGroup>
              {Object.entries(canisterFilter).map(([field, filter]) => (
                <DropdownMenuItem
                  className="cursor-pointer"
                  key={field}
                  onSelect={(e) => {
                    if (filter.type === "select") {
                      e.preventDefault();
                      e.stopPropagation();
                      setActiveFilter(filter);
                    } else {
                      setSelectedFilters([
                        ...selectedFilters,
                        { ...filter, value: filter.options[0] },
                      ]);
                    }
                  }}
                >
                  {/* {<filter.icon />} */}
                  <label
                    htmlFor={field}
                    className="text-sm font-medium capitalize"
                  >
                    {filter.label}
                  </label>
                  <DropdownMenuShortcut>
                    {field.charAt(0).toUpperCase()}
                  </DropdownMenuShortcut>
                </DropdownMenuItem>
              ))}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        ) : (
          <DropdownMenuContent align="start" className="w-52">
            <FilterItem
              field={activeFilter.field}
              filter={activeFilter}
              onSelect={(newFilter) => {
                setSelectedFilters([...selectedFilters, newFilter]);
              }}
              onClose={() => {
                setOpen(false);
                setActiveFilter(null);
              }}
            />
          </DropdownMenuContent>
        )}
      </DropdownMenu>
      <div className="flex flex-wrap gap-1 py-1">
        {selectedFilters.map((filter, index) => {
          const availableComparators = FILTER_COMPARATORS[filter.type];
          return (
            <FilterComponent
              key={index}
              filter={filter}
              onChange={(updatedFilter) =>
                handleFilterChange(index, updatedFilter)
              }
              onRemove={() => {
                setSelectedFilters([
                  ...selectedFilters.slice(0, index),
                  ...selectedFilters.slice(index + 1),
                ]);
              }}
              comparators={availableComparators}
              values={
                filter.type === "select" && "options" in filter
                  ? filter.options
                  : []
              }
            />
          );
        })}
      </div>
    </div>
  );
}
