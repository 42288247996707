import { Principal } from "@dfinity/principal";
import { ChevronDown } from "lucide-react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

import { Tags } from "@/components/canister-list/table/cells";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuSeparator,
  DropdownMenuInput,
} from "@/components/ui/dropdown-menu";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import {
  useCanisterTableDetailQuery,
  useAllCanistersTags,
  useCanisterTags,
} from "@/hooks/queries/canisters";
import {
  useProjectsQuery,
  useCanisterProjectMutation,
  useBatchCanisterTagMutation,
} from "@/hooks/queries/projects";
import {
  AppLink,
  useCurrentTeamQuery,
  useTeamMembersQuery,
} from "@/hooks/queries/team";
import { cn } from "@/lib/ui-utils";

import { CanisterSettingsLayout } from "./canister-settings";

const NO_PROJECT = "__no_project__";

function SingleCanisterTagsMenu({
  canisterId,
  className,
}: {
  canisterId: Principal;
  className?: string;
}) {
  const existingTags = useAllCanistersTags();
  const canisterTags = useCanisterTags(canisterId);
  const mutation = useBatchCanisterTagMutation();

  function handleTags(tag: string, type: "add" | "remove") {
    mutation.mutate({
      canisterIds: [canisterId],
      tag,
      type,
    });
  }

  function handleNewTag(tag: string) {
    handleTags(tag, "add");
  }

  const tags = existingTags.data
    ?.map((tag) => {
      if (!tag) return null;
      const checked = canisterTags.data?.includes(tag) ?? false;
      return { tag, checked };
    })
    .filter((tag): tag is { tag: string; checked: boolean } => !!tag);

  return (
    <div className="space-y-4">
      {canisterTags.data?.length ? <Tags value={canisterTags.data} /> : null}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div
            className={cn(
              "flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 max-w-[400px]",
              !canisterTags.data?.length && "text-muted-foreground"
            )}
          >
            <span className="flex-1">Select tags...</span>
            <ChevronDown className="h-4 w-4 opacity-50" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[400px]">
          {tags?.map(({ tag, checked }) => (
            <DropdownMenuCheckboxItem
              key={tag}
              checked={checked}
              onSelect={(e) => {
                handleTags(tag, checked ? "remove" : "add");
                e.preventDefault();
              }}
              className="gap-2 items-center"
            >
              {tag}
            </DropdownMenuCheckboxItem>
          ))}
          {!!tags?.length && <DropdownMenuSeparator />}
          <DropdownMenuInput onSubmit={handleNewTag} placeholder="New tag" />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

export default function CanisterOrganizationPage() {
  const { canisterId: canisterIdString } = useParams();
  const canisterId = canisterIdString
    ? Principal.fromText(canisterIdString)
    : undefined;

  const canister = useCanisterTableDetailQuery(canisterId);
  const projects = useProjectsQuery();
  const { mutate: updateProject, isPending: isUpdatingProject } =
    useCanisterProjectMutation();

  const handleProjectChange = async (projectName: string) => {
    if (!canisterId) return;
    if (isUpdatingProject) return;

    updateProject({
      canisterId,
      projectName: projectName === NO_PROJECT ? undefined : projectName,
    });
  };

  return (
    <CanisterSettingsLayout>
      <div className="flex flex-col gap-4">
        <Card>
          <CardHeader>
            <CardTitle>Tags</CardTitle>
            <CardDescription>
              Add tags to help categorize and filter your canisters
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                {canisterId && (
                  <SingleCanisterTagsMenu
                    canisterId={canisterId}
                    className="w-full"
                  />
                )}
              </div>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Project</CardTitle>
            <CardDescription>
              Assign this canister to a project for better organization
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <Select
                  value={canister.data?.project ?? NO_PROJECT}
                  onValueChange={handleProjectChange}
                  disabled={isUpdatingProject}
                >
                  <SelectTrigger className="max-w-[400px]">
                    <SelectValue placeholder="Select a project" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={NO_PROJECT}>No Project</SelectItem>
                    {projects.data?.map((project) => (
                      <SelectItem key={project.name} value={project.name}>
                        {project.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Team Transfer</CardTitle>
            <CardDescription>
              Transfer this canister to another team
            </CardDescription>
          </CardHeader>
          <Separator className="mb-4" />
          <CardContent className="text-sm gap-4 flex flex-col">
            <div>
              Individual canisters can't be transferred between teams, however
              projects can be. If you'd like to move this canister to another
              team, you can do so by adding it to a project, and then moving the
              project to the new team.
            </div>
            <div className="text-muted-foreground italic">
              {canister.data?.project ? (
                <div>
                  This canister is in the{" "}
                  <strong>"{canister.data.project}"</strong> project.
                </div>
              ) : (
                <div>
                  This canister is not in any project. Add it to a project to
                  transfer it to another team.
                </div>
              )}
            </div>
          </CardContent>
          <CardFooter>
            <AppLink to={`/projects`}>
              <Button variant="outline">View Projects</Button>
            </AppLink>
          </CardFooter>
        </Card>
      </div>
    </CanisterSettingsLayout>
  );
}
