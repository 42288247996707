import { Principal } from "@dfinity/principal";
import { differenceInDays, format, formatDuration } from "date-fns";
import { Loader2 } from "lucide-react";
import { useMemo, useState } from "react";
import {
  Brush,
  CartesianGrid,
  TooltipProps,
  XAxis,
  YAxis,
  ComposedChart,
  Bar,
  ReferenceArea,
} from "recharts";

import { TC, TCtoUSD } from "@/components/tc";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { usePaginatedCanistersQuery } from "@/hooks/queries/canisters";
import { useCanisterBurnInsight } from "@/hooks/queries/insights";
import { sumInsight } from "@/insights";
import { useTimeSettingsStore } from "@/state/stores/time-settings";

import { ChartConfig, ChartContainer, ChartTooltip } from "../ui/chart";
import { ChartBrushTraveller } from "./ChartBrushTraveller";
import { TimeSpanString } from "./shared";
import { useChartBrush } from "./useChartBrush";

function generateBurnData() {
  const modes = {
    hi: { min: 5, max: 25 },
    mid: { min: 0.5, max: 2 },
    lo: { min: 0, max: 0.1 },
  };

  const modeKeys = Object.keys(modes) as Array<keyof typeof modes>;
  const selectedMode = modeKeys[Math.floor(Math.random() * modeKeys.length)]!;
  const { min, max } = modes[selectedMode];

  const data = [];
  const now = new Date();
  const startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days ago
  const periodDuration = 1000 * 60 * 60 * 6; // 6 hours in milliseconds

  // Add null group at the start (first 3 entries)
  for (let i = 0; i < 3; i++) {
    const timestamp = new Date(startDate.getTime() + i * 6 * 60 * 60 * 1000);
    data.push({
      timestamp,
      burnThisPeriod: 0,
      burnPerDay: 0,
      periodDuration,
    });
  }

  // Generate regular data
  for (let i = 3; i < 30 * 4 - 4; i++) {
    const timestamp = new Date(startDate.getTime() + i * 6 * 60 * 60 * 1000);

    // Randomly insert null values
    if (Math.random() < 0.1) {
      // 10% chance of single null
      data.push({
        timestamp,
        burnThisPeriod: 0,
        burnPerDay: 0,
        periodDuration,
      });
      continue;
    }

    // 5% chance of starting a contiguous group of nulls
    if (Math.random() < 0.05) {
      const nullGroupLength = Math.floor(Math.random() * 4) + 2; // 2-5 nulls in a row
      for (let j = 0; j < nullGroupLength && i < 30 * 4; j++, i++) {
        const nullDate = new Date(startDate.getTime() + i * 6 * 60 * 60 * 1000);
        data.push({
          timestamp: nullDate,
          burnThisPeriod: 0,
          burnPerDay: 0,
          periodDuration,
        });
      }
      i--; // Adjust for the extra increment in the outer loop
      continue;
    }

    const burnThisPeriod = Math.random() * (max - min) + min;
    const burnPerDay =
      burnThisPeriod * ((1000 * 60 * 60 * 24) / periodDuration);
    data.push({
      timestamp,
      burnThisPeriod,
      burnPerDay,
      periodDuration,
    });
  }

  // Add null group at the end (last 4 entries)
  for (let i = 0; i < 4; i++) {
    const timestamp = new Date(
      startDate.getTime() + (30 * 4 - 4 + i) * 6 * 60 * 60 * 1000
    );
    data.push({
      timestamp,
      burnThisPeriod: null,
      burnPerDay: null,
      periodDuration,
    });
  }

  return data;
}

interface BurnData {
  timestamp: Date;
  burnThisPeriod: number;
  burnPerDay: number;
  periodDuration: number;
}

function CustomTooltip({
  active,
  payload,
  label,
  ...props
}: TooltipProps<number, string>) {
  const data = payload?.[0]?.payload as BurnData | undefined;

  if (!data) return null;

  // If burn data is missing, show a different tooltip
  if (!data.burnThisPeriod) {
    return (
      <div className="min-w-[8rem] rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl flex flex-col">
        <div className="font-medium mb-1">Cycles Burn</div>
        <div className="flex gap-1 justify-between">
          <div className="text-muted-foreground">Time</div>
          <div className="font-mono">
            {data.timestamp && format(data.timestamp, "MMM d, HH:mm")}
          </div>
        </div>
        <div className="italic text-xs mt-1">
          Data unavailable for this period.
        </div>
      </div>
    );
  }

  // Original tooltip for valid burn data
  return (
    <div className="min-w-[8rem] rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl flex flex-col">
      <div className="font-medium mb-1">Cycles Burn</div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Time</div>
        <div className="font-mono">
          {data.timestamp && format(data.timestamp, "MMM d, HH:mm")}
        </div>
      </div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Burn</div>
        <div className="font-mono">
          <TC value={data.burnThisPeriod} /> TC
        </div>
      </div>
    </div>
  );
}

export function ChartFigure({
  main,
  subheader,
}: {
  main: React.ReactNode;
  subheader?: React.ReactNode;
}) {
  return (
    <div className="text-xl font-bold flex flex-col leading-none">
      <span>{main}</span>
      {subheader && <div className="font-normal text-sm">{subheader}</div>}
    </div>
  );
}

export function BurnChart({ canisterId }: { canisterId?: Principal }) {
  const query = usePaginatedCanistersQuery();
  const timeSettings = useTimeSettingsStore();
  const insight = useCanisterBurnInsight(canisterId, timeSettings);

  const chartData = useMemo(() => {
    return insight.points.map((x) => ({
      timestamp: x[0],
      burnThisPeriod: Number(x[1]) / 1e12,
    }));
  }, [insight]);

  // const insight = generateBurnData();

  // const chartData = useMemo(
  //   () =>
  //     insight.map((x) => ({
  //       timestamp: x.timestamp,
  //       burnThisPeriod: x.burnThisPeriod,
  //     })),
  //   [insight]
  // );

  const chartConfig = {
    burn: { label: "Burn" },
    burnThisPeriod: {
      label: "Burn This Period",
      color: "hsl(var(--burn))",
    },
    elapsed: {
      label: "Period Duration",
    },
    burnPerDay: {
      label: "Burn Rate (Per Day)",
    },
  } satisfies ChartConfig;

  // State for brush selection with new hook state
  const {
    brushProps,
    brushStartIndex,
    brushEndIndex,
    startDate,
    endDate,
    daysDifference,
    formatString,
  } = useChartBrush({
    data: chartData,
  });

  // Calculate total burn for the selected period
  const totalBurn = useMemo(
    () => sumInsight(insight, brushStartIndex, brushEndIndex + 1),
    [insight, brushStartIndex, brushEndIndex, timeSettings]
  );

  // Conditional tick formatter using formatString from hook
  const tickFormatter = (value: string) => {
    const date = new Date(value);
    return format(date, formatString);
  };

  const missingDataSections = useMemo(() => {
    const sections: { start: number; end: number }[] = [];
    let currentSection: { start: number; end: number } | null = null;

    chartData?.forEach((point, index) => {
      // A falsey value such as null, 0, undefined, means that we are missing data for this period
      const isMissing = !point.burnThisPeriod;

      // If we are not missing data, close current section if it exists, and return
      if (!isMissing) {
        if (currentSection) {
          // The missing section ends at the start of this period
          currentSection.end = point.timestamp.getTime();
          // - (point.periodDuration ?? 0)
          sections.push(structuredClone(currentSection));
          currentSection = null;
        }
        return;
      }

      // If we are missing data, start a new section or continue current one
      if (!currentSection) {
        currentSection = {
          start: point.timestamp.getTime(),
          end: point.timestamp.getTime(),
        };
      }

      // If we are at the end of the chart, extend the current section to the end of the last period
      if (index === chartData.length - 1) {
        currentSection.end = point.timestamp.getTime();
        sections.push(structuredClone(currentSection));
        currentSection = null;
      }
    });

    return sections;
  }, [chartData]);

  return (
    <Card className="rounded-sm pb-3 bg-transparent">
      <CardHeader className="px-3 py-3 gap-2">
        <CardTitle className="text-sm">
          Cycles Burn{" "}
          <TimeSpanString
            startDate={startDate}
            endDate={endDate}
            formatString={formatString}
          />
        </CardTitle>
        {chartData.length > 0 && (
          <ChartFigure
            main={
              <>
                <TC value={totalBurn} /> TC
                <span className="text-muted-foreground">
                  {" "}
                  (<TCtoUSD value={totalBurn} />)
                </span>
              </>
            }
            subheader={
              daysDifference !== undefined
                ? `burned in ${daysDifference} day${
                    daysDifference === 1 ? "" : "s"
                  }`
                : undefined
            }
          />
        )}
      </CardHeader>
      <CardContent className="px-2 py-2 relative">
        {!query.isFetched && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Loader2 className="h-4 w-4 animate-spin" />
          </div>
        )}
        <ChartContainer config={chartConfig} className="h-[200px] w-full">
          <ComposedChart
            accessibilityLayer
            data={chartData}
            margin={{
              left: 20,
              right: 0,
              top: 20,
            }}
          >
            <defs>
              <pattern
                id="missing-data-pattern"
                patternUnits="userSpaceOnUse"
                width="8"
                height="8"
                patternTransform="rotate(45)"
              >
                <line
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="8"
                  stroke="hsl(var(--foreground))"
                  strokeWidth="1"
                  strokeOpacity="0.25"
                />
              </pattern>
            </defs>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="timestamp"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={tickFormatter}
            />
            <YAxis
              yAxisId="right"
              key="burnThisPeriod"
              tickLine={true}
              axisLine={false}
              tickFormatter={(value) => `${value.toFixed(2)} TC`}
              orientation="right"
            />
            <ChartTooltip
              cursor={{
                strokeWidth: 2,
              }}
              content={<CustomTooltip />}
            />
            {/* Render missing data sections using Area */}
            {/* <Area
              yAxisId="right"
              dataKey={(data) => {
                const maxValue = Math.max(
                  ...chartData.map((d) => d.burnThisPeriod || 0)
                );
                return data.burnThisPeriod === null ? maxValue : 0;
              }}
              stroke="none"
              fill="url(#missing-data-pattern)"
              baseValue={0}
              isAnimationActive={false}
            /> */}
            {missingDataSections.map((point, i) => (
              <ReferenceArea
                key={`missing-${i}`}
                x1={point.start}
                x2={point.end}
                yAxisId="right"
                fill="url(#missing-data-pattern)"
                fillOpacity={1}
              />
            ))}
            <Bar
              yAxisId="right"
              dataKey="burnThisPeriod"
              barSize={20}
              fill="hsl(var(--burn))"
            />
            <Brush {...brushProps} traveller={<ChartBrushTraveller />} />
          </ComposedChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
