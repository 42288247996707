import { ChevronUp, Command, ArrowBigUpDash } from "lucide-react";
import { useId } from "react";

export function Key({ children }: { children: React.ReactNode }) {
  return (
    <kbd className="rounded-sm border border-border bg-muted/50 text-xs font-medium text-muted-foreground flex items-center justify-center w-6 h-6">
      {children}
    </kbd>
  );
}

export function KeyCombo({ keys }: { keys: string[] }) {
  const uuid = useId();
  const normalizedKeys = keys.map((key) => {
    const k = key.toLowerCase();
    switch (k) {
      case "shift":
        return <ArrowBigUpDash className="w-4 h-4" />;
      case "command":
      case "cmd":
        return <Command className="w-3 h-3" />;
      case "ctrl":
      case "control":
        return <Command className="w-3 h-3" />;
      case "alt":
      case "option":
        return <ChevronUp className="w-3 h-3" />;
      default:
        return key;
    }
  });
  const Keys = normalizedKeys.map((key) => (
    <Key key={`${uuid}-${key}`}>{key}</Key>
  ));
  const JoinedKeys = Keys.reduce((acc: React.ReactNode[], key, i) => {
    if (i === 0) return [key];
    return [
      ...acc,
      <span key={`${uuid}-sep-${i}`} className="text-muted-foreground">
        +
      </span>,
      key,
    ];
  }, []);
  return (
    <div className="flex items-center justify-center gap-1">{JoinedKeys}</div>
  );
}
