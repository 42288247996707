import { useCallback, useState } from "react";
import { toast } from "sonner";

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useTransferProjectMutation } from "@/hooks/queries/projects";
import { useCallerTeamsQuery } from "@/hooks/queries/team";
import { useIdp } from "@/state/stores/idp";

type Project = {
  name: string;
};

export function TransferProjectDialog({
  project,
  open,
  onOpenChange,
}: {
  project: Project | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  const { principal } = useIdp();
  const teams = useCallerTeamsQuery();
  const { mutateAsync, isPending } = useTransferProjectMutation();

  const [transferTo, setTransferTo] = useState("");

  const handleSubmitTransfer = async () => {
    if (!project) return;
    const newProjectOwner =
      transferTo === "My Canisters"
        ? principal
        : teams.data?.find((t) => t.username === transferTo)?.principal;
    if (!newProjectOwner) return;

    try {
      await mutateAsync({
        projectName: project.name,
        newProjectOwner,
      });
      toast.success(`Successfully transferred project "${project.name}"`);
      onOpenChange(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("Failed to transfer project");
      }
    }
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Transfer Project</AlertDialogTitle>
          <AlertDialogDescription>
            Transfer the "{project?.name}" project to another team.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await handleSubmitTransfer();
          }}
        >
          <div>
            <div className="space-y-4 py-2 pb-4">
              <div className="space-y-2">
                <Label htmlFor="name">Transfer to</Label>
                <Select
                  value={transferTo}
                  onValueChange={(v) => setTransferTo(v)}
                >
                  <SelectTrigger className="w-40">
                    <SelectValue placeholder="Select a team" />
                  </SelectTrigger>
                  <SelectContent position="popper">
                    {teams.data?.map((team) => (
                      <SelectItem key={team.username} value={team.username}>
                        {team.displayName}
                      </SelectItem>
                    ))}
                    <SelectItem value="My Canisters">My Canisters</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
          <AlertDialogFooter>
            <Button
              variant="outline"
              type="button"
              onClick={() => onOpenChange(false)}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isPending} loading={isPending}>
              Continue
            </Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export function useTransferProjectDialog() {
  const [project, setProject] = useState<Project | null>(null);
  const [open, setOpen] = useState(false);

  const openTransferProject = useCallback((projectToTransfer: Project) => {
    setProject(projectToTransfer);
    setOpen(true);
  }, []);

  return {
    TransferProjectDialog: useCallback(
      () => (
        <TransferProjectDialog
          project={project}
          open={open}
          onOpenChange={setOpen}
        />
      ),
      [project, open]
    ),
    openTransferProject,
  };
}
