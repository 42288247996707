import { Principal } from "@dfinity/principal";

import {
  useAllCanistersTags,
  useCanisterTags,
} from "@/hooks/queries/canisters";
import { useBatchCanisterTagMutation } from "@/hooks/queries/projects";

import { Tags } from "./canister-list/table/cells";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuSeparator,
  DropdownMenuInput,
} from "./ui/dropdown-menu";

export function EditableCanisterTags({
  canisterId,
}: {
  canisterId: Principal;
}) {
  const existingTags = useAllCanistersTags();
  const canisterTags = useCanisterTags(canisterId);
  const mutation = useBatchCanisterTagMutation();

  function handleTags(tag: string, type: "add" | "remove") {
    mutation.mutate({
      canisterIds: [canisterId],
      tag,
      type,
    });
  }

  function handleNewTag(tag: string) {
    handleTags(tag, "add");
  }

  const tags = existingTags.data
    ?.map((tag) => {
      if (!tag) return null;
      const checked = canisterTags.data?.includes(tag) ?? false;
      return { tag, checked };
    })
    .filter((tag): tag is { tag: string; checked: boolean } => !!tag);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="cursor-pointer">
          <Tags value={canisterTags.data} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        {tags?.map(({ tag, checked }) => (
          <DropdownMenuCheckboxItem
            key={tag}
            checked={checked}
            onSelect={(e) => {
              handleTags(tag, checked ? "remove" : "add");
              e.preventDefault();
            }}
            className="gap-2 items-center"
          >
            {tag}
          </DropdownMenuCheckboxItem>
        ))}
        {!!tags?.length && <DropdownMenuSeparator />}
        <DropdownMenuInput onSubmit={handleNewTag} placeholder="New tag" />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
