import { EarlyAccessFeature } from "posthog-js";
import { useFeatureFlagEnabled } from "posthog-js/react";

import {
  useExperimentalFeatures,
  usePostExperimentalFeatureEnrollment,
} from "@/hooks/queries/features";

import { Card } from "./ui/card";
import { Switch } from "./ui/switch";

export function ExperimentalFeatures() {
  const features = useExperimentalFeatures();

  return (
    <div className="space-y-2">
      {features.data?.map((feature) => (
        <FeatureToggle key={feature.flagKey} feature={feature} />
      ))}
    </div>
  );
}

function FeatureToggle({ feature }: { feature: EarlyAccessFeature }) {
  const mutation = usePostExperimentalFeatureEnrollment();
  const flag = useFeatureFlagEnabled(feature.flagKey!);

  const handleToggle = (key: string | null) => {
    mutation.mutate({ name: feature.flagKey!, active: !flag });
  };

  return (
    <Card className="flex flex-col space-y-2 p-4 border">
      <div className="flex items-center justify-between ">
        <span>{feature.name}</span>
        <Switch
          checked={flag}
          onCheckedChange={() => handleToggle(feature.flagKey)}
        />
      </div>
      <div className="text-sm text-muted-foreground">{feature.description}</div>
    </Card>
  );
}
