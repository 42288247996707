import { Principal } from "@dfinity/principal";
import { format } from "date-fns";
import { Maximize2 } from "lucide-react";
import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { useCanisterTableDetailQuery } from "@/hooks/queries/canisters";
import { useCanisterMetadataQuery } from "@/hooks/queries/internet-computer";
import { AppLink, useRoute } from "@/hooks/queries/team";
import { useLatestCanisterChargeQuery } from "@/hooks/queries/transactions";
import { cn } from "@/lib/ui-utils";

import {
  BurnAmount,
  FreezeEstimate,
  monitoringMechanism,
  NullCell,
} from "./canister-list/table/cells";
import { BalanceChart } from "./chart/balance";
import { BurnChart } from "./chart/burn";
import { MemoryChart } from "./chart/memory";
import { QueryCallsChart } from "./chart/query-calls";
import { TopupChart } from "./chart/topup";
import { EditableCanisterProject } from "./editable-canister-project";
import { EditableCanisterTags } from "./editable-canister-tags";
import StatusIndiciator from "./status-indicator";
import { TC } from "./tc";
import { Button } from "./ui/button";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "./ui/navigation-menu";
import { Sheet, SheetContent, SheetHeader } from "./ui/sheet";
import { Skeleton } from "./ui/skeleton";

export default function CanisterSheet({
  canisterId,
  children,
  open,
  setOpen,
}: {
  canisterId?: Principal;
  children?: React.ReactNode;
  open?: boolean;
  setOpen?: (o: boolean) => void;
}) {
  const canister = useCanisterTableDetailQuery(canisterId);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent className="w-[50dvw] max-w-full sm:max-w-full overflow-auto">
        <div className="flex flex-col gap-4">
          <SheetHeader className="flex flex-row items-center justify-between">
            <AppLink to={`/canisters/detail/${canisterId?.toText()}`}>
              <Button variant="ghost" size="icon">
                <Maximize2 className="h-4 w-4" />
              </Button>
            </AppLink>
          </SheetHeader>
          <div className="">{children}</div>
        </div>
      </SheetContent>
    </Sheet>
  );
}

export function CanisterMonitoring() {
  const { canisterId: canisterIdString } = useParams();
  const canisterId = canisterIdString
    ? Principal.fromText(canisterIdString)
    : undefined;
  const canister = useCanisterTableDetailQuery(canisterId);

  return (
    <div className="px-1 py-4 md:p-4 flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <div className="flex max-md:flex-col gap-4">
          <h1 className="text-lg font-semibold max-md:px-2">
            {canister?.data?.name}
          </h1>
          {canisterId && (
            <div className="flex gap-2">
              <EditableCanisterProject canisterId={canisterId} />
              <EditableCanisterTags canisterId={canisterId} />
            </div>
          )}
        </div>
        {/* <TimeSettings /> */}
      </div>
      <div className="@container">
        <div className="grid grid-cols-1 gap-1.5 md:gap-4 @[800px]:grid-cols-2">
          <CanisterKitchenSink id={canisterId} />
          <BurnChart canisterId={canisterId} />
          <TopupChart canisterId={canisterId} />
          <BalanceChart canisterId={canisterId} />
          <MemoryChart canisterId={canisterId} />
          <QueryCallsChart canisterId={canisterId} />
        </div>
      </div>
    </div>
  );
}

export function CanisterSheetMenu({ detail }: { detail?: boolean }) {
  const { canisterId: canisterIdString } = useParams();
  const canisterId = canisterIdString
    ? Principal.fromText(canisterIdString)
    : undefined;
  const location = useLocation();
  const route = useRoute();

  function isActive(href: string, exact = false) {
    return exact
      ? location.pathname === route(href)
      : location.pathname.includes(href);
  }

  return (
    <NavigationMenu className="p-1 border border-border/50 rounded-lg bg-table">
      <NavigationMenuList className="">
        <NavigationMenuItem>
          <AppLink
            to={`/canisters/${detail ? "detail/" : ""}${canisterIdString}/`}
          >
            <NavigationMenuLink
              className={cn(
                navigationMenuTriggerStyle(),
                "text-xs bg-transparent border-transparent transition-colors duration-100",
                isActive(
                  `/canisters/${detail ? "detail/" : ""}${canisterIdString}/`,
                  true
                ) && "bg-muted/50 border border-border/50"
              )}
            >
              Overview
            </NavigationMenuLink>
          </AppLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <AppLink
            to={`/canisters/${
              detail ? "detail/" : ""
            }${canisterIdString}/settings`}
          >
            <NavigationMenuLink
              className={cn(
                navigationMenuTriggerStyle(),
                "text-xs bg-transparent border-transparent transition-colors duration-100",
                isActive(
                  `/canisters/${
                    detail ? "detail/" : ""
                  }${canisterIdString}/settings`
                ) && "bg-muted/50 border border-border/50"
              )}
            >
              Settings
            </NavigationMenuLink>
          </AppLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

function CanisterKitchenSink({ id }: { id?: Principal }) {
  const canister = useCanisterTableDetailQuery(id);
  return (
    <div className="text-sm @container">
      <div className="grid grid-cols-1 @[500px]:grid-cols-2 gap-1.5 md:gap-4 max-lg:text-xs">
        <SinkColumn className="col-span-2 @[400px]:col-span-1">
          <SinkRow>
            <SinkLabel>Canister Status</SinkLabel>
            <SinkValue>
              <StatusIndiciator
                status={canister?.data?.status}
                className="max-lg:size-2"
              />
            </SinkValue>
          </SinkRow>
          <SinkRow>
            <SinkLabel>Next top-up est.</SinkLabel>
            <SinkValue>
              {canister?.data?.nextTopUpEst ? (
                format(canister?.data?.nextTopUpEst, "MMM d")
              ) : (
                <NullCell />
              )}
            </SinkValue>
          </SinkRow>
          <SinkRow>
            <SinkLabel>Last top-up</SinkLabel>
            <SinkValue>
              <LastTopup canisterId={id} />
            </SinkValue>
          </SinkRow>
          <SinkRow>
            <SinkLabel>Monitoring</SinkLabel>
            <SinkValue>
              <div className="">
                {monitoringMechanism(canister.data?.monitoringMechanism)}
              </div>
            </SinkValue>
          </SinkRow>
          <SinkRow>
            <SinkLabel>Last monitor</SinkLabel>
            <SinkValue>
              {canister.data?.lastBalanceCheck ? (
                format(canister.data?.lastBalanceCheck, "h:mma MMM d")
              ) : (
                <NullCell />
              )}
            </SinkValue>
          </SinkRow>
          <SinkRow>
            <SinkLabel>Next monitor</SinkLabel>
            <SinkValue>
              {canister.data?.nextBalanceCheck ? (
                format(canister.data?.nextBalanceCheck, "h:mma MMM d")
              ) : (
                <NullCell />
              )}
            </SinkValue>
          </SinkRow>
        </SinkColumn>
        <SinkColumn className="col-span-2 @[400px]:col-span-1">
          <SinkRow>
            <SinkLabel>Cycles burn</SinkLabel>
            <SinkValue>
              <div className="flex gap-2">
                <BurnAmount value={canister.data?.burnPerDay} /> (24hr)
              </div>
            </SinkValue>
          </SinkRow>
          <SinkRow>
            <SinkLabel> </SinkLabel>
            <SinkValue>
              <div className="flex gap-2">
                <BurnAmount value={canister.data?.burnTotal} /> (30d)
              </div>
            </SinkValue>
          </SinkRow>
          <SinkRow>
            <SinkLabel>Idle burn</SinkLabel>
            <SinkValue>
              <div className="flex gap-2">
                <BurnAmount value={canister.data?.idleBurnPerDay} /> (24hr)
              </div>
            </SinkValue>
          </SinkRow>
          <SinkRow>
            <SinkLabel>Freezing threshold</SinkLabel>
            <SinkValue>{canister.data?.freezing.threshold.days}d</SinkValue>
          </SinkRow>
          <SinkRow>
            <SinkLabel>
              30 days × <TC value={canister.data?.idleBurnPerDay} /> per day =
            </SinkLabel>
            <SinkValue>
              {canister.data && (
                <>
                  <TC value={canister.data.freezing.threshold.cycles / 1e12} />{" "}
                  TC
                </>
              )}
            </SinkValue>
          </SinkRow>
          <SinkRow>
            <SinkLabel>Est. time to freeze</SinkLabel>
            <SinkValue>
              <FreezeEstimate
                value={canister.data?.freezing.timeUntilFreezeEst}
              />
            </SinkValue>
          </SinkRow>
        </SinkColumn>
        <SinkColumn className="col-span-2">
          <SinkRow>
            <SinkLabel>Canister ID</SinkLabel>
            <SinkValue>
              <div className="font-mono text-xs">{id?.toText()}</div>
            </SinkValue>
          </SinkRow>
          <SinkRow>
            <SinkLabel>Subnet</SinkLabel>
            <SinkValue>
              <div className="font-mono text-xs">
                {id && <Subnet canisterId={id} />}
              </div>
            </SinkValue>
          </SinkRow>
          <SinkRow>
            <SinkLabel>Module hash</SinkLabel>
            <SinkValue>
              <div className="font-mono text-xs">
                {canister.data?.moduleHash}
              </div>
            </SinkValue>
          </SinkRow>
          <SinkRow>
            <SinkLabel>
              Controllers{" "}
              {!!canister.data?.controllers?.length && (
                <>({canister.data.controllers.length})</>
              )}
            </SinkLabel>
            <SinkValue className="flex flex-col gap-0.5 items-end">
              {canister.data?.controllers?.map((controller) => (
                <div key={controller.toText()} className="font-mono text-xs">
                  {controller.toText()}
                </div>
              ))}
            </SinkValue>
          </SinkRow>
        </SinkColumn>
      </div>
    </div>
  );
}

function SinkColumn({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "border rounded-sm p-2 md:p-4 flex flex-col gap-1",
        className
      )}
    >
      {children}
    </div>
  );
}

function SinkRow({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={cn("flex justify-between items-baseline gap-2", className)}>
      {children}
    </div>
  );
}

function SinkLabel({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <div className={cn("", className)}>{children}</div>;
}

function SinkValue({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={cn("text-muted-foreground font-light", className)}>
      {children}
    </div>
  );
}

export function Subnet({ canisterId }: { canisterId: Principal }) {
  const metadata = useCanisterMetadataQuery(canisterId.toText());
  const subnet = metadata.data?.subnet;
  if (!metadata.isFetched) return <Skeleton className="w-4 h-4" />;
  return subnet;
}

function LastTopup({ canisterId }: { canisterId?: Principal }) {
  const charge = useLatestCanisterChargeQuery(canisterId);
  if (!charge.isFetched) return <Skeleton className="w-8 h-2" />;
  if (!charge.data) return <NullCell />;
  return (
    <div>
      {format(charge.data.timestamp, "MMM d")} /{" "}
      <TC value={charge.data.cycles / 1e12} /> TC
    </div>
  );
}
