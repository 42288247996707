import { Principal } from "@dfinity/principal";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { CellContext, Row } from "@tanstack/react-table";
import { format, getYear, isSameYear } from "date-fns";
import {
  Activity,
  Box,
  Copy,
  CopyCheck,
  ExternalLink,
  Globe,
  KeyRound,
  ListIcon,
  MemoryStick,
} from "lucide-react";
import React, { MouseEvent } from "react";
import { toast } from "sonner";

import {
  LogVisibility as LogVisibilityT,
  MonitoringCanisterType__1,
  TopupRule,
} from "common/declarations/cycleops/cycleops.did.d";

import { TC } from "@/components/tc";
import { Skeleton } from "@/components/ui/skeleton";
import { useCanisterMetadataQuery } from "@/hooks/queries/internet-computer";
import { useCanisterTableStore } from "@/hooks/stores/canister-table-store";
import { CanisterTableData } from "@/lib/insights/canister-insights";
import { cn, mapBytesSize } from "@/lib/ui-utils";

import { Blip } from "../../status-indicator";
import { Checkbox } from "../../ui/checkbox";
import { Separator } from "../../ui/separator";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../ui/tooltip";
import { FixedWidthCell } from "./fixed-width-cell";

export function NullCell() {
  return <div className="text-muted">—</div>;
}

export const MemoizedNullCell = React.memo(NullCell);

function Pill({
  icon,
  tooltip,
  children,
  className,
}: {
  icon?: React.ReactNode;
  tooltip?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}) {
  const classNames = cn("h-full cursor-default pl-2 flex items-center");
  const element =
    tooltip === undefined ? (
      icon && <div className={classNames}>{icon}</div>
    ) : (
      <Tooltip>
        <TooltipTrigger className={classNames}>
          {icon}
          <TooltipPortal>
            <TooltipContent side="bottom" align="start" sideOffset={3}>
              {tooltip}
            </TooltipContent>
          </TooltipPortal>
        </TooltipTrigger>
      </Tooltip>
    );
  return (
    <div
      className={cn(
        "h-8 flex items-center pr-1 border rounded-full text-sm group",
        className
      )}
    >
      {element}
      {children}
    </div>
  );
}

export const MemoizedPill = React.memo(Pill);

function PillStat({
  tooltip,
  children,
  className,
}: {
  tooltip?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <>
      <Tooltip>
        <TooltipTrigger
          className={cn(
            "h-full flex items-center px-2 text-xs cursor-default",
            className
          )}
        >
          {children}
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side="bottom" sideOffset={3}>
            {tooltip}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <Separator orientation="vertical" className="h-1/2 last:hidden" />
    </>
  );
}

export const MemoizedPillStat = React.memo(PillStat);

function PillCell({
  title,
  icon,
  stats,
}: {
  title?: React.ReactNode;
  icon: React.ReactNode;
  stats: {
    id: string;
    element: React.ReactNode;
    tooltip: React.ReactNode;
  }[];
}) {
  const columnVisibility = useCanisterTableStore(
    (state) => state.columnVisibility
  );

  // Filter out stats that are hidden
  const visible = stats.filter(({ id }) => columnVisibility[id]);

  if (!visible.length) return null;

  return (
    <Pill
      icon={icon}
      tooltip={
        <div className="flex flex-col gap-1">
          {title && <span className="text-xs font-medium">{title}</span>}
          {visible.map(({ tooltip, id }) => (
            <div className="flex gap-1" key={`tooltip-${title}-${id}`}>
              {tooltip}
            </div>
          ))}
        </div>
      }
    >
      {visible.map(({ id, element, tooltip }) => (
        <PillStat
          tooltip={tooltip}
          className="gap-0.5"
          key={`stat-${title}-${id}`}
        >
          {element}
        </PillStat>
      ))}
    </Pill>
  );
}

export const MemoizedPillCell = React.memo(PillCell);

export function BurnAmount({ value }: { value?: bigint | number }) {
  if (!value) return <NullCell />;
  return (
    <>
      <TC value={value} figs={2} /> TC
    </>
  );
}

export const MemoizedBurnAmount = React.memo(BurnAmount);

function BurnAmountTooltip({
  value,
  label,
}: {
  value?: bigint | number;
  label: string;
}) {
  if (!value)
    return (
      <>
        <span className="italic text-muted-foreground">Insuffient data</span>{" "}
        {label}
      </>
    );
  return (
    <>
      <TC value={value} figs={6} /> TC {label}
    </>
  );
}

export const MemoizedBurnAmountTooltip = React.memo(BurnAmountTooltip);

export function BurnPill({ row }: { row: Row<CanisterTableData> }) {
  return (
    <MemoizedPillCell
      icon="🔥"
      title="Burn stats"
      stats={[
        {
          id: "Burn (24hr)",
          element: <BurnAmount value={row.original.burnPerDay} />,
          tooltip: (
            <BurnAmountTooltip
              value={row.original.burnPerDay}
              label="burned in past 24 hours"
            />
          ),
        },
        {
          id: "Burn (30d)",
          element: <BurnAmount value={row.original.burnTotal} />,
          tooltip: (
            <BurnAmountTooltip
              value={row.original.burnTotal}
              label="burned in past 30 days"
            />
          ),
        },
        {
          id: "Idle Burn",
          element: (
            <BurnAmount value={Number(row.original.idleBurnPerDay) / 1e12} />
          ),
          tooltip: (
            <BurnAmountTooltip
              value={Number(row.original.idleBurnPerDay) / 1e12}
              label="idle cycles burned per day"
            />
          ),
        },
      ]}
    />
  );
}

export const MemoizedBurnPill = React.memo(BurnPill);

export function FreezeEstimate({ value }: { value?: Date }) {
  if (!value) return <NullCell />;
  if (isSameYear(value, new Date())) return format(value, "MMM d");
  if (getYear(value) > getYear(new Date()) + 1) return format(value, "yyyy");
  return format(value, "MMM yyyy");
}

export const MemoizedFreezeEstimate = React.memo(FreezeEstimate);

export function FreezeEstimateTooltip({ value }: { value?: Date }) {
  if (!value)
    return (
      <span className="italic text-muted-foreground">
        Insuffient data to estimate freezing date.
      </span>
    );
  return <>Estimated freeze date: {format(value, "MMMM dd, yyyy")}</>;
}

export const MemoizedFreezeEstimateTooltip = React.memo(FreezeEstimateTooltip);

export function FreezePill({ row }: { row: Row<CanisterTableData> }) {
  return (
    <MemoizedPillCell
      icon="❄️"
      title="Freeze stats"
      stats={[
        {
          id: "Freeze Est.",
          element: (
            <FreezeEstimate value={row.original.freezing.timeUntilFreezeEst} />
          ),
          tooltip: (
            <FreezeEstimateTooltip
              value={row.original.freezing.timeUntilFreezeEst}
            />
          ),
        },
        {
          id: "Threshold (Days)",
          element: `${row.original.freezing.threshold.days}d`,
          tooltip: `Freezing threshold in days: ${row.original.freezing.threshold.days}`,
        },
        {
          id: "Threshold (Cycles)",
          element: (
            <>
              <TC
                value={row.original.freezing.threshold.cycles / 1e12}
                figs={2}
              />{" "}
              TC
            </>
          ),
          tooltip: (
            <>
              Freezing threshold in cycles:{" "}
              <TC
                value={row.original.freezing.threshold.cycles / 1e12}
                figs={6}
              />{" "}
              TC
            </>
          ),
        },
      ]}
    />
  );
}

export const MemoizedFreezePill = React.memo(FreezePill);

function RuleCell({ value }: { value: TopupRule }) {
  const amount =
    "by_amount" in value.method
      ? value.method.by_amount
      : value.method.to_balance;
  return (
    <>
      <TC value={amount} figs={2} />
      @
      <TC value={value.threshold} figs={2} /> TC
    </>
  );
}

export const MemoizedRuleCell = React.memo(RuleCell);

function LastTopUpCell({ value }: { value?: Date }) {
  if (!value) return <NullCell />;
  return format(value, "MMM d");
}

export const MemoizedLastTopUpCell = React.memo(LastTopUpCell);

function LastTopUpTooltip({ value }: { value?: Date }) {
  if (!value)
    return (
      <>
        Last top-up occurred on{" "}
        <span className="italic text-muted-foreground">insufficient data</span>
      </>
    );
  return `Last top-up occurred on ${format(value, "MMM d, yyyy h:mm a")}`;
}

export const MemoizedLastTopUpTooltip = React.memo(LastTopUpTooltip);

function NextTopUpCell({ value }: { value?: Date }) {
  if (!value) return <NullCell />;
  return format(value, "MMM d");
}

export const MemoizedNextTopUpCell = React.memo(NextTopUpCell);

function NextTopUpTooltip({ value }: { value?: Date }) {
  if (!value)
    return (
      <span className="italic text-muted-foreground">
        Insufficient data to estimate next top-up
      </span>
    );
  return `Next top-up estimated for ${format(value, "MMM d, yyyy")}`;
}

export const MemoizedNextTopUpTooltip = React.memo(NextTopUpTooltip);

export function AutomationPill({ row }: { row: Row<CanisterTableData> }) {
  return (
    <MemoizedPillCell
      icon="🤖"
      title="Automation"
      stats={[
        {
          id: "Top-up Rule",
          element: <RuleCell value={row.original.rule} />,
          tooltip: <Rule value={row.original.rule} />,
        },
        // {
        //   id: "Last Top-up",
        //   element: <LastTopUpCell value={row.original.lastTopUp?.date} />,
        //   tooltip: <LastTopUpTooltip value={row.original.lastTopUp?.date} />,
        // },
        {
          id: "Next Top-up",
          element: <NextTopUpCell value={row.original.nextTopUpEst} />,
          tooltip: <NextTopUpTooltip value={row.original.nextTopUpEst} />,
        },
      ]}
    />
  );
}

export const MemoizedAutomationPill = React.memo(AutomationPill);

export function Select({ row }: CellContext<CanisterTableData, unknown>) {
  return (
    <Tooltip>
      <TooltipTrigger>
        <label
          data-interactive
          className={cn(
            "h-full flex items-center justify-center px-2",
            "opacity-50 lg:opacity-0 hover:opacity-100",
            row.getIsSelected() && "opacity-100 lg:opacity-100"
          )}
        >
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        </label>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent className="flex items-center gap-2">
          <kbd className="text-xs aspect-square h-5 flex items-center justify-center rounded-sm border border-border bg-muted/30">
            x
          </kbd>
          Select row
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
}

export const MemoizedSelect = React.memo(Select);

function Cell({
  children,
  tooltip,
  className,
}: {
  children?: React.ReactNode;
  tooltip?: React.ReactNode;
  className?: string;
}) {
  const classNames = cn(
    "text-xs cursor-default text-left flex items-center",
    className
  );
  if (tooltip)
    return (
      <Tooltip>
        <TooltipTrigger className={classNames}>{children}</TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" side="bottom" sideOffset={3}>
            {tooltip}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    );

  return <div className={classNames}>{children}</div>;
}

export const MemoizedCell = React.memo(Cell);

export function ID({ value }: { value: Principal }) {
  const text = value.toText();
  const start = text.slice(0, 5);

  function handleClick(e: MouseEvent) {
    toast("Copied to clipboard"!, {
      id: `copy-${text}`,
      description: "Canister ID has been copied to your clipboard.",
      icon: <CopyCheck className="w-5 h-5" />,
    });
    navigator.clipboard.writeText(text);
  }

  return (
    <Tooltip>
      <TooltipTrigger
        className={cn(
          "text-xs tracking-wider font-mono text-muted-foreground relative group",
          "flex items-center"
        )}
        onClick={handleClick}
      >
        {start}
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent
          onClick={handleClick}
          side="bottom"
          sideOffset={10}
          align="start"
          className="text-xs flex gap-2 items-center px-2 cursor-pointer font-mono"
          data-interactive
        >
          <Copy className="w-3 h-3" /> {text}
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
}

export const MemoizedID = React.memo(ID);

export function Name({ value }: { value: string }) {
  return (
    <Cell className="flex-grow text-sm font-medium overflow-hidden text-ellipsis">
      {value}
    </Cell>
  );
}

export const MemoizedName = React.memo(Name);

export function Balance({ value }: { value: bigint }) {
  return (
    <FixedWidthCell columnId="balance" className="text-xs flex justify-center">
      <Cell tooltip="Current balance">
        {Math.floor(Number(value) / 1e12)} TC
      </Cell>
    </FixedWidthCell>
  );
}

export const MemoizedBalance = React.memo(Balance);

export function MemorySize({ value }: { value?: bigint }) {
  return (
    <FixedWidthCell
      columnId="memory-size"
      className="text-xs flex justify-center"
    >
      <Cell tooltip="Current memory size">{mapBytesSize(Number(value))}</Cell>
    </FixedWidthCell>
  );
}

export const MemoizedMemorySize = React.memo(MemorySize);

export function Status({
  value,
}: {
  value: "healthy" | "unhealthy" | "frozen" | "pending";
}) {
  return (
    <Cell tooltip={<span className="capitalize">{value}</span>}>
      <Blip className="w-3 h-3" status={value} />
    </Cell>
  );
}

export const MemoizedStatus = React.memo(Status);

function Rule({ value }: { value: TopupRule }) {
  return `Top up ${
    "by_amount" in value.method
      ? `by ${Number(value.method.by_amount) / 1e12} TC`
      : ""
  } ${
    "to_balance" in value.method
      ? `to ${Number(value.method.to_balance) / 1e12} TC`
      : ""
  } when below ${Number(value.threshold) / 1e12} TC`;
}

export function Tags({ value }: { value?: string[] }) {
  if (!value?.length) return null;
  return (
    <div className="flex gap-1">
      {value.map((tag) => (
        <Pill
          key={tag}
          className=""
          icon={<div className="w-2 h-2 bg-muted rounded-full" />}
        >
          <div className="px-2 text-xs">{tag}</div>
        </Pill>
      ))}
    </div>
  );
}

export const MemoizedTags = React.memo(Tags);

export function Project({ value }: { value?: string }) {
  if (!value) return null;
  return (
    <Pill icon={<Box className="w-4 h-4" />}>
      <div className="px-2 text-xs">{value}</div>
    </Pill>
  );
}

export const MemoizedProject = React.memo(Project);

export function NumericCell({ value }: { value?: number | bigint }) {
  if (!value) return null;
  return <div className="text-xs text-center">{Number(value)}</div>;
}

export const MemoizedNumericCell = React.memo(NumericCell);

export function monitoringMechanism(value?: MonitoringCanisterType__1) {
  if (!value) return "Unknown";
  const mechanism = (() => {
    if ("nns" in value) return "NNS";
    if ("sns" in value) return "SNS";
    if ("publicStatus" in value) return "Public";
    if ("blackhole" in value) return `Blackhole V${Number(value.blackhole)}`;
    return "Unknown";
  })();
  return mechanism;
}

export function MonitoringMechanismCell({
  value,
}: {
  value: MonitoringCanisterType__1;
}) {
  return (
    <FixedWidthCell columnId="monitoring-mechanism">
      <Cell
        className="text-center capitalize"
        tooltip={`This canister is monitored via ${monitoringMechanism(value)}`}
      >
        {monitoringMechanism(value)}
      </Cell>
    </FixedWidthCell>
  );
}

export const MemoizedMonitoringMechanism = React.memo(MonitoringMechanismCell);

export function Controllers({ value }: { value?: Principal[] }) {
  return (
    <Cell
      className="flex items-center gap-1"
      tooltip={
        <div className="flex flex-col gap-1">
          <div className="text-xs font-medium">Controllers</div>
          {value?.map((principal) => (
            <div key={principal.toText()} className="text-xs font-mono">
              {principal.toText()}
            </div>
          ))}
        </div>
      }
    >
      <KeyRound className="w-3 h-3" /> {value?.length}
    </Cell>
  );
}

export const MemoizedControllers = React.memo(Controllers);

export function Subnet({ canisterId }: { canisterId: Principal }) {
  const metadata = useCanisterMetadataQuery(canisterId.toText());
  const subnet = metadata.data?.subnet;
  return (
    <Cell
      className={cn(
        "text-xs tracking-wider font-mono text-muted-foreground relative group cursor-default",
        "flex items-center"
      )}
      tooltip={
        <>
          Canister is deployed on subnet{" "}
          <a
            href={`https://dashboard.internetcomputer.org/subnet/${subnet}`}
            target="_blank"
            className="flex items-center gap-1 font-mono underline"
          >
            {subnet?.slice(0, 5)}...
            {subnet?.slice(-3)}
            <ExternalLink className="w-3 h-3" />
          </a>
        </>
      }
    >
      <div className="text-[10px] text-center flex items-center gap-1">
        {metadata.isFetched ? (
          <>
            <Globe className="w-3 h-3" /> {subnet?.slice(0, 5)}
          </>
        ) : (
          <Skeleton className="w-4 h-4" />
        )}
      </div>
    </Cell>
  );
}

export const MemoizedSubnet = React.memo(Subnet);

function WasmMemoryLimit({ value }: { value?: bigint }) {
  if (!value) return null;
  return (
    <Cell
      className="text-xs text-center flex items-center gap-1"
      tooltip="WASM Memory Limit"
    >
      <MemoryStick className="w-3 h-3" /> {mapBytesSize(Number(value))}
    </Cell>
  );
}

export const MemoizedWasmMemoryLimit = React.memo(WasmMemoryLimit);

function LogVisibility({ value }: { value?: LogVisibilityT }) {
  if (!value) return null;
  const v = (() => {
    if ("controllers" in value) return "Controllers";
    if ("public" in value) return "Public";
    if ("allowed_viewers" in value) return "Allowed Viewers";
    throw new Error("Invalid log visibility");
  })();
  return (
    <Cell
      className="text-xs text-center flex items-center gap-1"
      tooltip="Log Visibility"
    >
      <ListIcon className="w-3 h-3" /> {v}
    </Cell>
  );
}

export const MemoizedLogVisibility = React.memo(LogVisibility);

function QueryCallsTotal({ value }: { value?: bigint }) {
  if (!value) return null;
  return (
    <Cell
      className="text-xs text-center flex items-center gap-1"
      tooltip="Query Calls Total"
    >
      <Activity className="w-3 h-3" /> {Number(value).toLocaleString("en-US")}
    </Cell>
  );
}

export const MemoizedQueryCallsTotal = React.memo(QueryCallsTotal);
