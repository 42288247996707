import { Toaster } from "sonner";

export default function ToastProvider() {
  return (
    <Toaster
      theme="system"
      visibleToasts={9}
      duration={10000}
      toastOptions={{
        className:
          "bg-transparent bg-gradient-to-br from-muted/50 to-bg/50 backdrop-blur-md border border-border/75 shadow-sm",
      }}
    />
  );
}
