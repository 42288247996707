import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { queryClient } from "@/hooks/queries";
import { useCreateProjectMutation } from "@/hooks/queries/projects";
import { useCreateProjectDialogStore } from "@/hooks/stores/create-project-dialog-store";

export function CreateProjectDialog() {
  const { mutate: createProject, isPending } = useCreateProjectMutation();
  const { isOpen, projectName, close, setProjectName, reset } =
    useCreateProjectDialogStore();

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && close()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Project</DialogTitle>
          <DialogDescription>
            Create a new project to organize your canisters.
          </DialogDescription>
        </DialogHeader>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            createProject(
              { projectName },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["projects"],
                    refetchType: "all",
                  });
                  queryClient.invalidateQueries({
                    queryKey: ["projects-with-metrics"],
                    refetchType: "all",
                  });
                  reset();
                },
              }
            );
          }}
        >
          <div>
            <div className="space-y-4 py-2 pb-4">
              <div className="space-y-2">
                <Label htmlFor="name">Project name</Label>
                <Input
                  id="name"
                  placeholder="My new project"
                  value={projectName}
                  onChange={(e) => setProjectName(e.currentTarget.value)}
                />
              </div>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                Cancel
              </Button>
            </DialogClose>
            <Button type="submit" loading={isPending} disabled={isPending}>
              Continue
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
