import { Principal } from "@dfinity/principal";
import { differenceInDays, format } from "date-fns";
import { AlertTriangle } from "lucide-react";
import { useMemo } from "react";
import {
  Brush,
  CartesianGrid,
  TooltipProps,
  XAxis,
  YAxis,
  ComposedChart,
  Line,
  ReferenceLine,
  Label,
} from "recharts";
import { CartesianViewBox } from "recharts/types/util/types";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useCanisterTableDetailQuery } from "@/hooks/queries/canisters";
import { useCanisterMemorySizeInsight } from "@/hooks/queries/insights";
import { latestValue } from "@/insights";
import { mapBytesSize } from "@/lib/ui-utils";
import { useTimeSettingsStore } from "@/state/stores/time-settings";

import { ChartConfig, ChartContainer, ChartTooltip } from "../ui/chart";
import { ChartBrushTraveller } from "./ChartBrushTraveller";
import { ChartFigure } from "./burn";
import { ThresholdLabel, TimeSpanString } from "./shared";
import { useChartBrush } from "./useChartBrush";

interface MemoryData {
  timestamp: Date;
  memorySize: number | null;
}

interface ThresholdInfo {
  value: number;
  note?: string;
}

function generateMemoryData() {
  const profiles = {
    steadyIncrease: { base: 100_000_000, increment: 1_000_000 },
    rapidIncrease: { base: 100_000_000, increment: 5_000_000 },
    plateau: { base: 500_000_000, increment: 0 },
    fluctuating: { base: 300_000_000, maxVariation: 50_000_000 },
  } as const;

  const profileKeys = Object.keys(profiles) as Array<keyof typeof profiles>;
  const selectedProfile =
    profileKeys[Math.floor(Math.random() * profileKeys.length)]!;
  const profile = profiles[selectedProfile];

  const data: MemoryData[] = [];
  const now = new Date();
  const startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days ago

  let maxMemorySize = 0;

  for (let i = 0; i < 30 * 24; i++) {
    // Hourly data points
    const date = new Date(startDate.getTime() + i * 60 * 60 * 1000);
    let memorySize: number;

    if (
      selectedProfile === "steadyIncrease" ||
      selectedProfile === "rapidIncrease"
    ) {
      // @ts-expect-error: w/e
      memorySize = profile.base + i * profile.increment;
    } else if (selectedProfile === "plateau") {
      memorySize = profile.base;
    } else if (selectedProfile === "fluctuating") {
      memorySize =
        // @ts-expect-error: w/e
        profile.base + Math.floor(Math.random() * profile.maxVariation);
    } else {
      memorySize = profile.base;
    }

    maxMemorySize = Math.max(maxMemorySize, memorySize);

    data.push({
      timestamp: date,
      memorySize,
    });
  }

  // Set threshold to be 20% higher than the maximum value
  const threshold: ThresholdInfo = {
    value: Math.ceil(maxMemorySize * 1.2),
    note: "Automatic warning at 80% of canister memory capacity",
  };

  return { data, threshold };
}

function CustomTooltip({
  active,
  payload,
  label,
  threshold,
}: TooltipProps<number, string> & { threshold?: number }) {
  const data = payload?.[0]?.payload as MemoryData | undefined;

  if (!data) return null;

  if (!data.memorySize) {
    return (
      <div className="min-w-[8rem] rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl flex flex-col">
        <div className="font-medium mb-1">Memory Size</div>
        <div className="flex gap-1 justify-between">
          <div className="text-muted-foreground">Time</div>
          <div className="font-mono">
            {data.timestamp && format(data.timestamp, "MMM d, HH:mm")}
          </div>
        </div>
        <div className="italic text-xs mt-1">
          Data unavailable for this period.
        </div>
      </div>
    );
  }

  return (
    <div className="min-w-[8rem] rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl flex flex-col">
      <div className="font-medium mb-1">Memory Size</div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Time</div>
        <div className="font-mono">
          {data.timestamp && format(data.timestamp, "MMM d, HH:mm")}
        </div>
      </div>
      <div className="flex gap-1 justify-between">
        <div className="text-muted-foreground">Size</div>
        <div className="font-mono">{mapBytesSize(Number(data.memorySize))}</div>
      </div>
    </div>
  );
}

export function MemoryChart({ canisterId }: { canisterId?: Principal }) {
  const timeSettings = useTimeSettingsStore();
  const canister = useCanisterTableDetailQuery(canisterId);
  const insight = useCanisterMemorySizeInsight(canisterId, timeSettings);

  const chartData = useMemo(
    () =>
      insight.points.map((x) => ({
        timestamp: x[0],
        memorySize: Number(x[1]),
      })),
    [insight]
  );

  const threshold = canister.data?.memoryThreshold;
  const thresholdValue = threshold ? Number(threshold) : undefined;

  // const insight = generateMemoryData();
  // const chartData = insight.data.map((x) => ({
  //   timestamp: x.timestamp,
  //   memorySize: x.memorySize ?? 0,
  // }));
  // const thresholdValue = insight.threshold.value;

  const chartConfig = {
    memory: {
      label: "Memory Size",
      color: "white",
    },
  } satisfies ChartConfig;

  // State for brush selection with new hook state
  const { brushProps, startDate, endDate, formatString } = useChartBrush({
    data: chartData,
  });

  // Conditional tick formatter using formatString from hook
  const tickFormatter = (value: string) => {
    const date = new Date(value);
    return format(date, formatString);
  };

  // Get the current memory size
  const [lastTimestamp, currentMemorySize] = useMemo(
    () => latestValue(insight),
    [insight, timeSettings]
  );

  const domain = useMemo(() => {
    if (!chartData?.length) return [0, 0];

    const maxMemory = Math.max(...chartData.map((d) => d.memorySize || 0));
    const minMemory = Math.min(
      ...chartData.filter((d) => d.memorySize > 0).map((d) => d.memorySize || 0)
    );

    // Add 20% padding above max value or threshold
    const upperBound = Math.max(maxMemory * 1.2, thresholdValue || 0);
    // Subtract 20% padding below min value, but don't go below 0
    const lowerBound = Math.max(0, minMemory * 0.8);

    return [lowerBound, upperBound];
  }, [chartData, thresholdValue]);

  return (
    <Card className="rounded-sm pb-3 bg-transparent">
      <CardHeader className="px-3 py-3 gap-2">
        <CardTitle className="text-sm">
          Memory Size{" "}
          <TimeSpanString
            startDate={startDate}
            endDate={endDate}
            formatString={formatString}
          />
        </CardTitle>
        {chartData && chartData.length > 0 && (
          <ChartFigure
            main={
              currentMemorySize
                ? mapBytesSize(Number(currentMemorySize))
                : "N/A"
            }
            subheader={
              lastTimestamp ? format(lastTimestamp, "MMM dd HH:mm") : "N/A"
            }
          />
        )}
      </CardHeader>
      <CardContent className="px-2 py-2">
        <ChartContainer config={chartConfig} className="h-[200px] w-full">
          <ComposedChart
            accessibilityLayer
            data={chartData}
            margin={{
              left: 20,
              right: 0,
              top: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="timestamp"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={tickFormatter}
            />
            <YAxis
              yAxisId="right"
              tickLine={true}
              axisLine={false}
              tickFormatter={mapBytesSize}
              orientation="right"
              domain={domain}
            />
            <ChartTooltip
              cursor={{
                strokeWidth: 2,
              }}
              content={<CustomTooltip threshold={thresholdValue} />}
            />
            {thresholdValue !== undefined && (
              <ReferenceLine
                yAxisId="right"
                y={thresholdValue}
                stroke="white"
                strokeDasharray="3 3"
              >
                <Label
                  position="insideLeft"
                  content={({ viewBox }) => (
                    <ThresholdLabel
                      viewBox={viewBox as CartesianViewBox}
                      icon={AlertTriangle}
                      text="Alert"
                      color="white"
                    />
                  )}
                />
              </ReferenceLine>
            )}
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="memorySize"
              stroke="white"
              dot={false}
              strokeWidth={2}
            />
            <Brush {...brushProps} traveller={<ChartBrushTraveller />} />
          </ComposedChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
