import { Loader2 } from "lucide-react";
import { useCallback, useState } from "react";
import { toast } from "sonner";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { queryClient } from "@/hooks/queries";
import {
  useProjectsQuery,
  useDeleteProjectMutation,
} from "@/hooks/queries/projects";

import { Button } from "./ui/button";

type Project = NonNullable<ReturnType<typeof useProjectsQuery>["data"]>[number];

export function DeleteProjectDialog({
  project,
  open,
  onOpenChange,
}: {
  project: Project | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  const deleteProject = useDeleteProjectMutation();

  const handleDelete = useCallback(async () => {
    if (!project) return;
    if (deleteProject.isPending) return;

    deleteProject.mutate(
      { projectName: project.name },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["projects"] });
          toast.success(`Successfully deleted project "${project.name}"`);
          onOpenChange(false);
        },
      }
    );
  }, [project, onOpenChange, deleteProject]);

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Project</AlertDialogTitle>
          <AlertDialogDescription>
            No canisters will be deleted. Are you sure you want to delete the{" "}
            <strong>{project?.name}</strong> project?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={deleteProject.isPending}>
            Cancel
          </AlertDialogCancel>
          <Button
            variant="destructive"
            loading={deleteProject.isPending}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export function useDeleteProjectDialog() {
  const [project, setProject] = useState<Project | null>(null);
  const [open, setOpen] = useState(false);

  const openDeleteProject = useCallback((projectToDelete: Project) => {
    setProject(projectToDelete);
    setOpen(true);
  }, []);

  return {
    DeleteProjectDialog: useCallback(
      () => (
        <DeleteProjectDialog
          project={project}
          open={open}
          onOpenChange={setOpen}
        />
      ),
      [project, open]
    ),
    openDeleteProject,
  };
}
