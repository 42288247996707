import { Principal } from "@dfinity/principal";
import { Cell, flexRender } from "@tanstack/react-table";
import React from "react";

import { CanisterTableData } from "@/lib/insights/canister-insights";
import { cn } from "@/lib/ui-utils";

import { CanisterListContextMenu } from "./context-menu";

interface DataTableRowProps {
  cells: Cell<CanisterTableData, unknown>[];
  isSelected: boolean;
  canisterId: Principal;
  onRowClick?: (id: Principal) => void;
  isHighlighted?: boolean;
  onHover?: () => void;
  style?: React.CSSProperties;
}

export default function DataTableRow({
  cells,
  style,
  onRowClick,
  isSelected,
  isHighlighted,
  onHover,
  canisterId,
}: DataTableRowProps) {
  const handleRowClick = (e: React.MouseEvent) => {
    // Only trigger row click if the event didn't originate from an interactive element
    if (
      !e.defaultPrevented &&
      onRowClick &&
      !(e.target as HTMLElement).closest(
        'button, a, input, [role="button"], [data-interactive]'
      )
    ) {
      onRowClick(canisterId);
    }
  };

  return (
    <CanisterListContextMenu selected={isSelected} canisterId={canisterId}>
      <div
        data-state={isSelected && "selected"}
        data-highlighted={isHighlighted}
        onClick={handleRowClick}
        onMouseEnter={onHover}
        className={cn(
          "group flex flex-row gap-2 items-center h-12 pr-2 shadow-[0_1px_0_hsla(var(--border))] transition-colors data-[state=selected]:data-[highlighted=false]:bg-muted/20 data-[highlighted=true]:bg-muted/40 cursor-default select-none text-nowrap"
        )}
        style={style}
      >
        {cells.map((cell) => (
          <React.Fragment key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </React.Fragment>
        ))}
      </div>
    </CanisterListContextMenu>
  );
}

const MemoizedDataTableRow = React.memo(DataTableRow);

export { MemoizedDataTableRow };
