import React from "react";

import { CanisterTableData } from "@/lib/insights/canister-insights";
import { cn } from "@/lib/ui-utils";

export type Status = "healthy" | "unhealthy" | "warning" | "pending";

export function statusAsIndex(status: Status) {
  switch (status) {
    case "healthy":
      return 0;
    case "unhealthy":
      return 1;
    case "warning":
      return 2;
    case "pending":
      return 3;
    default:
      throw new Error(`Unknown status: ${status}`);
  }
}

interface Props {
  children?: React.ReactNode;
  status?: Status | CanisterTableData["status"];
  className?: string;
}

export function Blip(props: {
  status?: Status | CanisterTableData["status"];
  className?: string;
}) {
  const status = getStatus(props.status);
  return (
    <div
      className={cn(
        "rounded-full block w-3 h-3",
        status === "healthy" && "bg-healthy",
        status === "unhealthy" && "bg-unhealthy",
        status === "warning" && "bg-warning",
        status === "pending" && "bg-pending",
        status === undefined && "bg-muted",
        props.className
      )}
    ></div>
  );
}

function getStatus(
  status?: Status | CanisterTableData["status"]
): Status | undefined {
  return status === "healthy"
    ? "healthy"
    : status === "unhealthy"
    ? "warning"
    : status === "frozen"
    ? "unhealthy"
    : status === "pending"
    ? "pending"
    : status;
}

export default function StatusIndiciator({ className, ...props }: Props) {
  const status = getStatus(props.status);
  return (
    <div
      className={cn(
        "flex gap-[10px] max-lg:gap-1.5 items-center capitalize cursor-default",
        props.status === "healthy" && "text-healthy",
        props.status === "unhealthy" && "text-unhealthy",
        props.status === "warning" && "text-warning",
        props.status === "pending" && "text-pending",
        props.status === undefined && "text-muted"
      )}
    >
      <Blip {...props} status={status} className={className} />
      {props.children ? props.children : props.status}
    </div>
  );
}
