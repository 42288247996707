import type { _SERVICE as CyclesLedger } from "common/declarations/cycles_ledger/cycles_ledger.did.d";

import { Principal } from "@dfinity/principal";
import { useMachine } from "@xstate/react";
import { ArrowDownCircle, Copy, Info } from "lucide-react";
import React from "react";
import { toast } from "sonner";
import {
  ActorRefFrom,
  SnapshotFrom,
  StateFrom,
  assign,
  fromPromise,
  sendParent,
  setup,
} from "xstate";

import { idlFactory as CyclesLedgerIDL } from "common/declarations/cycles_ledger/cycles_ledger.did";

import { copy, maskFloatInput } from "@/components/helper";
import { queryClient } from "@/hooks/queries";
import {
  fetchCyclesLedgerAllowance,
  useCyclesLedgerAllowanceQuery,
  useCyclesLedgerBalanceQuery,
} from "@/hooks/queries/ledger-cycles";
import { mapTrillions } from "@/lib/ic-utils";
import { connectPlug } from "@/lib/plug";
import { cn } from "@/lib/ui-utils";

import { Match, StateSwitch } from "./state-switch";
import { Button } from "./ui/button";
import { DialogClose, DialogHeader } from "./ui/dialog";
import { Input } from "./ui/input";
import { Label } from "./ui/label";

type PaymentMethod = "icp-account" | "cycles-ledger";
type WalletProvider = "plug" | "manual";

type UpdateApprovalEvent =
  | { type: "requestApproval"; amount: { e12s: number } }
  | { type: "validateApproval" }
  | { type: "back" };

interface Context {
  currentMethod: PaymentMethod;
  newMethod?: PaymentMethod;
  provider?: WalletProvider;
  approvalAddress?: string;
  approvalAmount?: { e12s: number };
}

export const updateApprovalMachine = setup({
  types: {
    context: {} as Context,
    input: {} as Context,
    events: {} as UpdateApprovalEvent,
  },

  actions: {
    handleError(e) {
      if ("error" in e.event) {
        console.error(e.event.error);
        toast.error((e.event.error as Error).message);
      } else {
        console.error(e);
      }
    },
  },

  guards: {
    isPlug({ context }) {
      return context.provider === "plug";
    },
  },

  actors: {
    validateApproval: fromPromise<unknown, { approvalAddress?: string }>(
      async ({ input: { approvalAddress } }) => {
        if (!approvalAddress) throw new Error("No approval address");
        queryClient.invalidateQueries({
          queryKey: ["cycles-ledger-allowance", approvalAddress],
        });
        const allowance = await fetchCyclesLedgerAllowance({
          owner: Principal.fromText(approvalAddress),
        });
        if (Number(allowance!.allowance) / 1e12 < 1) {
          throw new Error("Please approve at least 1T cycles");
        }
      }
    ),
    requestApprovalPlug: fromPromise<
      unknown,
      { approvalAmount?: { e12s: number } }
    >(async ({ input: { approvalAmount } }) => {
      if (!approvalAmount) throw new Error("No approval amount");
      await connectPlug();
      const cyclesLedger = await window.ic?.plug?.createActor<CyclesLedger>({
        canisterId: import.meta.env.CYOPS_CYCLES_LEDGER_CANISTER_ID,
        interfaceFactory: CyclesLedgerIDL,
      });
      const result = await cyclesLedger?.icrc2_approve({
        amount: BigInt(approvalAmount.e12s),
        spender: {
          owner: Principal.fromText(import.meta.env.CYOPS_MAIN_CANISTER_ID),
          subaccount: [],
        },
        fee: [BigInt(1e8)],
        memo: [],
        from_subaccount: [],
        created_at_time: [BigInt(Date.now() * 1e6)],
        expected_allowance: [],
        expires_at: [],
      });
    }),
  },
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOlgBd0AncgYgG0AGAXUVAAcB7WXc3T-GxAAPRAEYAzGJIBWAOwyx8uQDYpEgCxi5AGhABPRAFolJFQsYAmMSusSAHCtsSAvi71oseQqQrU69GKsSCBcPHwCQqIIYmIAnLIScXFi9pYqGlIaGjJ6hjFaJJaq9nJx2XGMlZJuHhg4BMQkAG7oADa4EOjkYACC7OxUnK1tALLo+ACu7bQQAmAkBM2cANYLng0+Le2d3X0DQyPjU+0IS5yY3fz4TMy3QmG811HiVQkqKXKMjGLFORpxPLiDSMWTpByaSyWZJpDS1EAbbxNEa7Hr9QbDdrHaZtWhgKhDKgkdhtboAM04VFQJERjVIKK6aIOmLGExxZ3wy0uERuLHuIUePJeCEUclkKhkKlijg0GTkGiBCEsmTMcTkclKMkYmUycXhtK26AgEHRh3aAAU2pMoLQqGAAI6TODkU0s-kcbhPSIhaIyCSguJqVKMeQSFSOFSKowgszmey-GRaGTWP0yfX1JGkI0m5kjS3W2gAIywK3doU9Qp9iEspSK8vlkrSWo0lijkksJDS6UYTiqlkTclc7gRGbpJGzrqObJmDL2k-aZcFzyrIv9JBs2XsjiUGnsWkVkgSVnsYey-rklkYZXTXjHE9zWOnuOLmFLLAeFeXoGiNbFf0HmSlEkgIGOIcT2CQEgOOYNYyPY2pQkOdS3lsdqOs685tPmNpzIQiycqs6yjqhDpOhQmHYRyXJXAItyLp+3rfq8jgkOBkhiCGThfJKbYqCQLYyIm1iXpe5hiDemxNGhZEug+WFWja+KEsSpLkBSVI0sRUmkRhcmUec3LXHR74Cgxggrs2naCdYGjqg4xT2Lx0hbikXZOJkPaWG4w74JwEBwEIBrEB+4RfiIxgSGKjBhleyQqNFrliFGyoJEoTiRS5KRqHCw5Bb4lA0CFXrmUxMQBmocSXgl8XxiB+Rweu9nlBIfo1mkSEjihyI7Iy+wYlOJxtEVlalfBYontoDhfD8bUHqY8jFOBVRwWl4m5VpWbGhRCnDWF0T+ho-HlPFcQSP2glyEloEICYEHfPEIZ7oJW49h1eXjltcnYu0u2MeFSrlCQViRd80KVPYgkSAeDisQCyTJsqW6OPYEmZiQ0m6f1Fo7aZoV-dEZQyOudgJXBcRwVGhSRSk50ZKU2go+tXWkLhYC-SV-2xP2JCXVCl2nZYZ2MI512Sp2lS7hkNgQ1oereUAA */
  context: ({ input }) => input,

  initial: "start",

  states: {
    start: {
      always: [
        {
          target: "addApprovalPlug",
          guard: "isPlug",
        },
        "addApprovalManual",
      ],
    },

    validateApprovalManual: {
      invoke: {
        src: "validateApproval",
        input: ({ context }) => ({ approvalAddress: context.approvalAddress }),
        onDone: "done",
        onError: {
          target: "addApprovalManual",
          actions: "handleError",
        },
      },
    },

    addApprovalPlug: {
      on: {
        requestApproval: {
          target: "requestApprovalPlug",
          actions: assign({ approvalAmount: ({ event }) => event.amount }),
        },

        back: {
          actions: sendParent({ type: "back" }),
        },
      },
    },

    addApprovalManual: {
      on: {
        validateApproval: "validateApprovalManual",
        back: {
          actions: sendParent({ type: "back" }),
        },
      },
    },

    requestApprovalPlug: {
      invoke: {
        src: "requestApprovalPlug",
        input: ({ context }) => ({ approvalAmount: context.approvalAmount }),
        onDone: "done",
        onError: {
          target: "addApprovalPlug",
          actions: "handleError",
        },
      },
    },

    done: {
      type: "final",
      output: ({ context }) => context,
    },
  },
});

export type UpdateApprovalState = StateFrom<typeof updateApprovalMachine>;
export type UpdateApprovalSend = (event: UpdateApprovalEvent) => void;

function WizardScreen({
  title,
  children,
  className,
}: React.ComponentPropsWithoutRef<"div"> & {
  title: string;
}) {
  return (
    <div className={cn("flex flex-col gap-8 items-start", className)}>
      <DialogHeader className="font-medium text-lg">{title}</DialogHeader>
      {children}
    </div>
  );
}

export interface UpdateApprovalScreenProps {
  state: UpdateApprovalState;
  send: UpdateApprovalSend;
  parented?: boolean;
}

function AddApprovalPlugScreen({
  state,
  send,
  parented = true,
}: UpdateApprovalScreenProps) {
  const principal = state.context.approvalAddress
    ? Principal.fromText(state.context.approvalAddress)
    : undefined;
  const balance = useCyclesLedgerBalanceQuery({ owner: principal });
  const allowance = useCyclesLedgerAllowanceQuery({ owner: principal });

  const loading = state.matches("requestApprovalPlug");

  const [amount, setAmount] = React.useState("0.0");
  const amount1e12 = React.useMemo(
    () => ({ e12s: Number(amount) * 1e12 }),
    [amount]
  );

  React.useEffect(() => {
    if (allowance.data?.allowance && amount === "0.0") {
      setAmount((Number(allowance.data.allowance) / 1e12).toFixed(2));
    }
  }, [allowance]);

  return (
    <WizardScreen title="Approve Funds">
      <div className="md:grid grid-cols-[2fr,_1fr] gap-12 space-y-8 md:space-y-0">
        <div className="space-y-2 w-full">
          <Label className="text-muted-foreground">Approved Amount</Label>
          <div className="w-full flex gap-2 items-center">
            <Input
              className="flex-1 text-base"
              value={amount}
              onChange={(e) => setAmount(maskFloatInput(e.currentTarget.value))}
            />
            <div className="text-sm">T Cycles</div>
          </div>
        </div>
        <div className="flex flex-col space-y-2 w-full">
          <div className="text-sm text-muted-foreground">Wallet Balance</div>
          <div className="text-xl flex-1 flex items-center">
            {balance ? (
              mapTrillions(Number(balance.data), true)
            ) : (
              <div className="text-sm">Loading...</div>
            )}
          </div>
        </div>
      </div>
      <Button
        variant={loading ? "secondary" : "default"}
        loading={loading}
        size="lg"
        onClick={() => send({ type: "requestApproval", amount: amount1e12 })}
      >
        {loading ? "Pending..." : "Approve"}
      </Button>
      <div className="space-y-3 w-full">
        <div className="flex justify-between items-center">
          {parented ? (
            <Button
              variant="ghost"
              size="sm"
              className="text-xs h-6 text-muted-foreground"
              onClick={() => send({ type: "back" })}
            >
              Back
            </Button>
          ) : (
            <div />
          )}
          <div className="text-xs text-muted text-right cursor-default">
            Update payment method
          </div>
        </div>
      </div>
    </WizardScreen>
  );
}

function AddApprovalManualScreen({
  state,
  send,
  parented = true,
}: UpdateApprovalScreenProps) {
  const loading = state.matches("validateApprovalManual");

  const principal = state.context.approvalAddress
    ? Principal.fromText(state.context.approvalAddress)
    : undefined;
  const allowance = useCyclesLedgerAllowanceQuery({ owner: principal });
  const balance = useCyclesLedgerBalanceQuery({ owner: principal });
  return (
    <WizardScreen title="Approve Funds">
      <div className="space-y-3">
        <div className="text-sm">
          From your wallet, approve the CycleOps canister to spend cycles on
          your behalf to top-up your canisters.{" "}
          <span className="text-muted-foreground">(Minimum 1T Cycles.)</span>
        </div>
        <div className="text-xs text-muted-foreground flex items-center gap-2">
          <Info size="14px" />
          <div>
            Need help? Read{" "}
            <a
              href="https://docs.cycleops.dev/docs/cycles-ledger"
              target="_blank"
            >
              how to approve funds
            </a>
            .
          </div>
        </div>
      </div>
      <div className="space-y-4">
        <div className="md:grid grid-cols-[2fr,_1fr] gap-8 space-y-8 md:space-y-0 w-full">
          <div className="space-y-2 w-full">
            <div className="text-sm text-muted-foreground">
              Your Wallet Address
            </div>
            <Input
              className="pointer-events-none"
              value={state.context.approvalAddress}
              disabled
            />
          </div>
          <div className="flex flex-col space-y-2 w-full">
            <div className="text-sm text-muted-foreground">Wallet Balance</div>
            <div className="text-xl flex-1 flex items-center">
              {allowance.data ? (
                mapTrillions(Number(balance.data), true)
              ) : (
                <div className="text-sm">Loading...</div>
              )}{" "}
              Cycles
            </div>
          </div>
        </div>
        <div className="md:grid grid-cols-[2fr,_1fr] gap-8 space-y-8 md:space-y-0 w-full">
          <div />
          <ArrowDownCircle className="mx-auto text-muted" />
        </div>
        <div className="md:grid grid-cols-[2fr,_1fr] gap-8 space-y-8 md:space-y-0 w-full">
          <div className="space-y-2 w-full">
            <div className="text-sm text-muted-foreground">
              CycleOps Address
            </div>
            <div
              className="relative cursor-pointer"
              onClick={() => copy(import.meta.env.CYOPS_MAIN_CANISTER_ID)}
            >
              <Input
                className="pointer-events-none"
                value={import.meta.env.CYOPS_MAIN_CANISTER_ID}
                disabled
              />
              <div className="h-full aspect-square absolute right-0 top-0 bg-muted text-foreground rounded flex items-center justify-center">
                <Copy size="16px" />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2 w-full">
            <div className="text-sm text-muted-foreground">
              CycleOps Allowance
            </div>
            <div className="text-xl flex-1 flex items-center">
              {allowance.data ? (
                mapTrillions(Number(allowance.data.allowance), true)
              ) : (
                <div className="text-sm">Loading...</div>
              )}{" "}
              Cycles
            </div>
          </div>
        </div>
      </div>
      <Button
        variant={loading ? "secondary" : "default"}
        loading={loading}
        size="lg"
        onClick={() => send({ type: "validateApproval" })}
      >
        {loading ? "Validating..." : "Validate"}
      </Button>
      <div className="space-y-3 w-full">
        <div className="flex justify-between items-center">
          {parented ? (
            <Button
              variant="ghost"
              size="sm"
              className="text-xs h-6 text-muted-foreground"
              onClick={() => send({ type: "back" })}
            >
              Back
            </Button>
          ) : (
            <div />
          )}
          <div className="text-xs text-muted text-right cursor-default">
            Update payment method
          </div>
        </div>
      </div>
    </WizardScreen>
  );
}

export function UpdateApprovalChildFlow({
  actor,
  parented = true,
}: {
  actor?: ActorRefFrom<typeof updateApprovalMachine>;
  parented?: boolean;
}) {
  const [state, setState] = React.useState<
    SnapshotFrom<typeof actor> | undefined
  >(actor?.getSnapshot());

  React.useEffect(() => {
    if (!actor) return undefined;
    setState(actor.getSnapshot());
    const sub = actor.subscribe(setState);
    return sub.unsubscribe;
  }, [actor]);

  if (!actor || !state) {
    console.warn("No actor provided to UpdateApprovalFlow.");
    return null;
  }

  const machine = { state, send: actor.send, parented };

  return (
    <>
      <StateSwitch state={state}>
        <Match state={["addApprovalPlug", "requestApprovalPlug"]}>
          <AddApprovalPlugScreen {...machine} />
        </Match>
        <Match state={["addApprovalManual", "validateApprovalManual"]}>
          <AddApprovalManualScreen {...machine} />
        </Match>
      </StateSwitch>
    </>
  );
}

export function UpdateApprovalFlow({
  provider,
  address,
}: {
  provider: WalletProvider;
  address: string;
}) {
  const [state, , actor] = useMachine(updateApprovalMachine, {
    input: {
      currentMethod: "cycles-ledger",
      provider,
      approvalAddress: address,
    },
  });

  return (
    <StateSwitch state={state}>
      <UpdateApprovalChildFlow actor={actor} parented={false} />
      <Match state="done">
        <WizardScreen title="Funds Approved!">
          <DialogClose asChild>
            <Button>Done</Button>
          </DialogClose>
        </WizardScreen>
      </Match>
    </StateSwitch>
  );
}
