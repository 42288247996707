import { createColumnHelper, FilterFn, RowData } from "@tanstack/react-table";

import { CanisterTableData } from "@/lib/insights/canister-insights";

import {
  MemoizedID,
  MemoizedStatus,
  MemoizedName,
  MemoizedTags,
  MemoizedProject,
  MemoizedAutomationPill,
  MemoizedFreezePill,
  MemoizedBurnPill,
  MemoizedControllers,
  MemoizedNumericCell,
  MemoizedMonitoringMechanism,
  MemoizedSubnet,
  MemoizedBalance,
  MemoizedMemorySize,
  MemoizedSelect,
  Select,
  MemoizedLogVisibility,
  MemoizedQueryCallsTotal,
  MemoizedWasmMemoryLimit,
} from "./cells";

export const COLUMN_VISIBILITY_GROUPS = [
  { id: "canister", label: "Canister" },
  { id: "usage", label: "Usage" },
  { id: "freezing", label: "Freezing" },
  { id: "organization", label: "Organization" },
  { id: "automation", label: "Top-Up Automation" },
  { id: "config", label: "Configuration" },
  { id: "meta", label: "Meta" },
] as const;

const column = createColumnHelper<CanisterTableData>();

declare module "@tanstack/react-table" {
  interface ColumnMeta<TData extends RowData, TValue> {
    defaultHidden?: boolean;
    visGroup?: (typeof COLUMN_VISIBILITY_GROUPS)[number]["id"];
  }
}

const columns = [
  column.display({
    id: "select",
    cell: Select,
  }),
  column.accessor("id", {
    id: "ID",
    cell: ({ cell }) => <MemoizedID value={cell.getValue()} />,
    meta: { visGroup: "canister" },
    enableHiding: true,
    enableSorting: true,
  }),
  column.accessor("status", {
    id: "Status",
    cell: ({ cell }) => <MemoizedStatus value={cell.getValue()} />,
    meta: { visGroup: "canister" },
    enableHiding: true,
    enableSorting: true,
    filterFn: "custom" as any,
  }),
  column.accessor("name", {
    id: "Name",
    cell: ({ cell }) => <MemoizedName value={cell.getValue()} />,
    enableSorting: true,
  }),
  column.accessor("tags", {
    id: "Tags",
    meta: {
      defaultHidden: false,
      visGroup: "organization",
    },
    cell: ({ cell }) => <MemoizedTags value={cell.getValue()} />,
    enableHiding: true,
    enableSorting: false,
  }),
  column.accessor("project", {
    id: "Project",
    meta: {
      defaultHidden: false,
      visGroup: "organization",
    },
    cell: ({ cell }) => <MemoizedProject value={cell.getValue()} />,
    enableHiding: true,
    enableSorting: false,
    filterFn: "custom" as any,
  }),
  column.group({
    id: "Automation",
    enableHiding: true,
    columns: [
      column.display({
        id: "automation-pill",
        cell: ({ row }) => <MemoizedAutomationPill row={row} />,
      }),
      column.accessor("rule", {
        id: "Top-up Rule",
        meta: { visGroup: "automation" },
        enableSorting: true,
        cell: () => null,
        enableHiding: true,
      }),
      // column.accessor("lastTopUp.date", {
      //   id: "Last Top-up",
      //   meta: {
      //     defaultHidden: true,
      //     visGroup: "automation",
      //   },
      //   enableSorting: true,
      //   cell: () => null,
      //   enableHiding: true,
      // }),
      column.accessor("nextTopUpEst", {
        id: "Next Top-up",
        meta: {
          defaultHidden: true,
          visGroup: "automation",
        },
        cell: () => null,
        enableHiding: true,
        enableSorting: true,
      }),
    ],
  }),
  column.group({
    id: "Freeze",
    enableHiding: true,
    columns: [
      column.display({
        id: "freeze-pill",
        cell: ({ row }) => <MemoizedFreezePill row={row} />,
      }),
      column.accessor("freezing.timeUntilFreezeEst", {
        id: "Freeze Est.",
        meta: {
          defaultHidden: false,
          visGroup: "freezing",
        },
        cell: () => null,
        enableHiding: true,
        enableSorting: true,
        filterFn: "custom" as any,
      }),
      column.accessor("freezing.threshold.days", {
        id: "Threshold (Days)",
        meta: {
          defaultHidden: true,
          visGroup: "freezing",
        },
        cell: () => null,
        enableHiding: true,
        enableSorting: true,
      }),
      column.accessor("freezing.threshold.cycles", {
        id: "Threshold (Cycles)",
        meta: {
          defaultHidden: true,
          visGroup: "freezing",
        },
        cell: () => null,
        enableHiding: true,
        enableSorting: true,
      }),
    ],
  }),
  column.group({
    id: "Burn",
    enableHiding: true,
    columns: [
      column.display({
        id: "burn-pill",
        cell: ({ row }) => <MemoizedBurnPill row={row} />,
      }),
      column.accessor("burnPerDay", {
        id: "Burn (24hr)",
        enableHiding: true,
        meta: { visGroup: "usage", defaultHidden: false },
        cell: () => null,
        enableSorting: true,
        filterFn: "custom" as any,
      }),
      column.accessor("burnTotal", {
        id: "Burn (30d)",
        enableHiding: true,
        meta: { visGroup: "usage", defaultHidden: false },
        cell: () => null,
        enableSorting: true,
        filterFn: "custom" as any,
      }),
      column.accessor("idleBurnPerDay", {
        id: "Idle Burn",
        meta: { defaultHidden: true, visGroup: "usage" },
        enableHiding: true,
        cell: () => null,
        enableSorting: true,
      }),
    ],
  }),
  column.accessor("controllers", {
    id: "Controllers",
    meta: { defaultHidden: true, visGroup: "config" },
    cell: ({ cell }) => <MemoizedControllers value={cell.getValue()} />,
    enableHiding: true,
    enableSorting: true,
    sortingFn: (a, b) => {
      return (
        (a.original.controllers?.length ?? 0) -
        (b.original.controllers?.length ?? 0)
      );
    },
  }),
  column.accessor("monitoringMechanism", {
    id: "Monitoring",
    meta: { defaultHidden: true, visGroup: "config" },
    cell: ({ cell }) => <MemoizedMonitoringMechanism value={cell.getValue()} />,
    enableHiding: true,
    enableSorting: false,
  }),
  column.accessor("computeAllocation", {
    id: "Compute Allocation",
    meta: { defaultHidden: true, visGroup: "config" },
    cell: ({ cell }) => <MemoizedNumericCell value={cell.getValue()} />,
    enableHiding: true,
    enableSorting: true,
  }),
  column.accessor("memoryAllocation", {
    id: "Memory Allocation",
    meta: { defaultHidden: true, visGroup: "config" },
    cell: ({ cell }) => <MemoizedNumericCell value={cell.getValue()} />,
    enableHiding: true,
    enableSorting: true,
  }),
  column.accessor("reservedCycles", {
    id: "Reserved Cycles",
    meta: { defaultHidden: true, visGroup: "config" },
    cell: ({ cell }) => <MemoizedNumericCell value={cell.getValue()} />,
    enableHiding: true,
    enableSorting: true,
  }),
  column.display({
    id: "Subnet",
    meta: { defaultHidden: true, visGroup: "meta" },
    cell: ({ row }) => <MemoizedSubnet canisterId={row.original.id} />,
    enableHiding: true,
    enableSorting: false,
  }),

  column.accessor("balance", {
    id: "Balance",
    meta: { visGroup: "usage" },
    enableHiding: true,
    cell: ({ cell }) => <MemoizedBalance value={cell.getValue()} />,
    enableSorting: true,
    filterFn: "custom" as any,
  }),
  column.accessor("memorySize", {
    id: "Memory Size",
    meta: { defaultHidden: false, visGroup: "usage" },
    cell: ({ cell }) => <MemoizedMemorySize value={cell.getValue()} />,
    enableHiding: true,
    enableSorting: true,
    filterFn: "custom" as any,
  }),

  column.accessor("logVisibility", {
    id: "Log Visibility",
    meta: { defaultHidden: true, visGroup: "config" },
    cell: ({ cell }) => <MemoizedLogVisibility value={cell.getValue()} />,
    enableHiding: true,
    enableSorting: false,
  }),
  column.accessor("queryCallsTotal", {
    id: "Query Calls Total",
    meta: { defaultHidden: true, visGroup: "usage" },
    cell: ({ cell }) => <MemoizedQueryCallsTotal value={cell.getValue()} />,
    enableHiding: true,
    enableSorting: false,
  }),
  column.accessor("wasmMemoryLimit", {
    id: "Wasm Memory Limit",
    meta: { defaultHidden: true, visGroup: "config" },
    cell: ({ cell }) => <MemoizedWasmMemoryLimit value={cell.getValue()} />,
    enableHiding: true,
    enableSorting: false,
  }),
];
export { columns };
