import {
  BatteryCharging,
  BatteryWarning,
  CommandIcon,
  Box,
  Tag,
  X,
  Settings,
  Check,
  Minus,
  ArrowBigUpDash,
} from "lucide-react";
import { useEffect, useState } from "react";
import { create } from "zustand";

import {
  useAllCanistersTags,
  useCanistersProject,
  useCanistersTags,
} from "@/hooks/queries/canisters";
import {
  useBatchCanisterProjectMutation,
  useBatchCanisterTagMutation,
  useProjectsQuery,
} from "@/hooks/queries/projects";
import { AppLink } from "@/hooks/queries/team";
import { useCanisterTable } from "@/hooks/stores/canister-table-store";
import { cn } from "@/lib/ui-utils";

import { KeyCombo } from "../key";
import { Button } from "../ui/button";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { ContextMenuInputItem } from "../ui/context-menu";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuInput,
} from "../ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import {
  ProjectCommandDialog,
  useProjectCommand,
} from "./commands/project-command";
import { useTagCommand } from "./commands/tag-command";
import { useSelectedCanisters } from "./table/context-menu";

// Tag Command Dialog State
interface TagCommandState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

function TagCommandDialog() {
  const { isOpen, close } = useTagCommand();
  const selectedCanisters = useSelectedCanisters();
  const existingTags = useAllCanistersTags();
  const selectionTags = useCanistersTags(selectedCanisters);
  const mutation = useBatchCanisterTagMutation();
  const [inputValue, setInputValue] = useState("");

  function handleTags(tag: string, type: "add" | "remove") {
    mutation.mutate({
      canisterIds: selectedCanisters,
      tag,
      type,
    });
  }

  function handleNewTag(value: string) {
    if (!value.trim()) return;
    handleTags(value, "add");
    setInputValue("");
  }

  const tags = existingTags.data
    ?.map((tag) => {
      if (!tag) return null;
      const checked = selectionTags.data?.[tag];
      return { tag, checked };
    })
    .filter(
      (tag): tag is { tag: string; checked: boolean | "indeterminate" } => !!tag
    );

  return (
    <CommandDialog open={isOpen} onOpenChange={close}>
      <div className="flex justify-start px-3 py-3">
        <div className="px-3 py-2 bg-muted/50 rounded-md">
          {selectedCanisters.length} Canisters
        </div>
      </div>
      <CommandInput
        placeholder="Search or create tags..."
        value={inputValue}
        onValueChange={setInputValue}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleNewTag(inputValue);
          }
        }}
      />
      <CommandList>
        <CommandEmpty>
          <div className="p-4 flex flex-col gap-2">
            <div className="text-sm text-muted-foreground">
              No matching tags found
            </div>
            {inputValue && (
              <Button
                variant="secondary"
                className="gap-2"
                onClick={() => handleNewTag(inputValue)}
              >
                <Tag className="w-4 h-4" />
                Create tag "{inputValue}"
              </Button>
            )}
          </div>
        </CommandEmpty>
        <CommandGroup>
          {tags?.map(({ tag, checked }) => (
            <CommandItem
              key={tag}
              onSelect={() =>
                handleTags(tag, checked === true ? "remove" : "add")
              }
              className="gap-2 items-center"
            >
              <div className="flex items-center gap-2 flex-1">
                <div
                  className={cn(
                    "h-4 w-4 border rounded-sm flex items-center justify-center",
                    (checked === true || checked === "indeterminate") &&
                      "bg-primary border-primary"
                  )}
                >
                  {checked === true && (
                    <Check className="w-3 h-3 text-primary-foreground" />
                  )}
                  {checked === "indeterminate" && (
                    <Minus className="w-3 h-3 text-primary-foreground" />
                  )}
                </div>
                {tag}
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  );
}

function ProjectDropdownMenu({ className }: { className?: string }) {
  const { open } = useProjectCommand();

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button
          variant="secondary"
          className="pl-3 h-full rounded-sm gap-3 bg-muted/50 border-border border"
          onClick={() => open()}
        >
          <Box className="w-4 h-4" />
          Move to project
        </Button>
      </TooltipTrigger>
      <TooltipContent className="flex gap-2 text-xs items-center">
        Move to project <KeyCombo keys={["shift", "P"]} />
      </TooltipContent>
    </Tooltip>
  );
}

function TagsDropdownMenu({ className }: { className?: string }) {
  const { open } = useTagCommand();

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button
          variant="secondary"
          className="pl-3 h-full rounded-sm gap-3 bg-muted/50 border-border border"
          onClick={() => open()}
        >
          <Tag className="w-4 h-4" />
          Add tags
        </Button>
      </TooltipTrigger>
      <TooltipContent className="flex gap-2 text-xs items-center">
        Add tags <KeyCombo keys={["shift", "T"]} />
      </TooltipContent>
    </Tooltip>
  );
}

function SelectedCanisterCommandPrompt({ className }: { className?: string }) {
  const table = useCanisterTable();
  const count = Object.values(table.getState().rowSelection).filter(
    (x) => !!x
  ).length;
  const visible = count > 0;

  function handleClear() {
    table.setRowSelection({});
  }

  const { open } = useCanisterCommand();

  return (
    <div
      className={cn(
        "flex justify-center w-full py-4 opacity-0 translate-y-full",
        "pointer-events-none",
        visible &&
          "translate-y-0 opacity-100 transition-all duration-200 ease-out",
        className
      )}
    >
      <div
        className={cn(
          "bg-background border border-border rounded-md p-2 px-4 pointer-events-auto flex gap-3 items-center"
        )}
      >
        <div className="rounded-sm border-dashed border flex items-center overflow-hidden">
          <div className="px-3 py-1 h-full font-medium">{count} selected</div>
          <Button
            variant="ghost"
            size="sm"
            className="text-muted-foreground border-l border-border border-dashed rounded-none hover:bg-muted/50"
            onClick={handleClear}
          >
            <X className="w-4 h-4" />
          </Button>
        </div>
        <div className="h-4/5 w-0 border-l-2 border-border" />
        <TagsDropdownMenu />
        <ProjectDropdownMenu />
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="secondary"
              className="gap-3 gap-3 bg-muted/50 border-border border"
              onClick={() => open()}
            >
              <CommandIcon className="w-4 h-4" />
              Command
            </Button>
          </TooltipTrigger>
          <TooltipContent className="flex gap-2 text-xs items-center">
            Command <KeyCombo keys={["command", "K"]} />
          </TooltipContent>
        </Tooltip>
        <CanisterCommandDialog />
        <TagCommandDialog />
        <ProjectCommandDialog />
      </div>
    </div>
  );
}

interface CanisterCommandState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export const useCanisterCommand = create<CanisterCommandState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  toggle: () => set((state) => ({ isOpen: !state.isOpen })),
}));

function CanisterCommandDialog() {
  const table = useCanisterTable();
  const { isOpen, toggle } = useCanisterCommand();
  const { open: openTags } = useTagCommand();
  const { open: openProjects } = useProjectCommand();

  return (
    <CommandDialog open={isOpen} onOpenChange={toggle}>
      <div className="flex justify-start px-3 py-3 ">
        <div className="px-3 py-2 bg-muted/50 rounded-md">
          {
            Object.values(table.getState().rowSelection).filter((x) => !!x)
              .length
          }{" "}
          Canisters
        </div>
      </div>
      <CommandInput placeholder="Type a command or search" />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          <CommandList>
            <CommandItem
              className="gap-2"
              onSelect={() => {
                toggle();
                openTags();
              }}
            >
              <Tag className="w-4 h-4" />
              <span>Change tags</span>
            </CommandItem>
            <CommandItem
              className="gap-2"
              onSelect={() => {
                toggle();
                openProjects();
              }}
            >
              <Box className="w-4 h-4" />
              <span>Move to project</span>
            </CommandItem>
            {/* <CommandItem className="gap-2">
              <BatteryWarning className="w-4 h-4" />
              <span>Set automatic top-up rule</span>
            </CommandItem>
            <CommandItem className="gap-2">
              <BatteryCharging className="w-4 h-4" />
              <span>Top-up now</span>
            </CommandItem> */}
          </CommandList>
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  );
}

export { SelectedCanisterCommandPrompt };
