import { useEffect, useCallback, useRef } from "react";

type ModifierKey = "meta" | "ctrl" | "shift" | "alt";
type KeyHandler = (e: KeyboardEvent) => void;

// Use the built-in TypeScript type for keyboard events
type KeyCode = KeyboardEvent["key"];

interface KeyBinding {
  key: KeyCode;
  modifiers?: ModifierKey[];
  description?: string;
  preventDefault?: boolean;
  handler: KeyHandler;
}

interface KeyMapOptions {
  /**
   * Whether to prevent default browser behavior for registered shortcuts
   * Can be overridden per binding
   */
  preventDefault?: boolean;

  /**
   * Whether the keyboard shortcuts should be active
   */
  enabled?: boolean;

  /**
   * Elements that should not trigger keyboard shortcuts when focused
   * @default 'input, textarea, [role="textbox"], [contenteditable="true"]'
   */
  ignoreFocusedElements?: string;

  /**
   * Elements that, when present, should disable keyboard shortcuts
   * @default '[role="dialog"], [role="modal"]'
   */
  blockingElements?: string;
}

/**
 * Creates a keyboard shortcut manager for a specific context/scope
 */
export function useKeyMap(bindings: KeyBinding[], options: KeyMapOptions = {}) {
  const {
    preventDefault: globalPreventDefault = true,
    enabled = true,
    ignoreFocusedElements = 'input, textarea, [role="textbox"], [contenteditable="true"]',
    blockingElements = '[role="dialog"], [role="modal"]',
  } = options;

  const bindingsRef = useRef(bindings);
  bindingsRef.current = bindings;

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!enabled) return;

      // Check if any blocking elements (modals, dialogs) are present
      if (blockingElements && document.querySelector(blockingElements)) {
        return;
      }

      // Check if an ignored element is focused
      const { activeElement } = document;
      if (
        activeElement &&
        ignoreFocusedElements &&
        activeElement.matches(ignoreFocusedElements)
      ) {
        return;
      }

      const activeModifiers = new Set<ModifierKey>();
      // Treat meta (Command) and ctrl as equivalent modifiers
      if (e.metaKey || e.ctrlKey) {
        activeModifiers.add("meta");
        activeModifiers.add("ctrl");
      }
      if (e.shiftKey) activeModifiers.add("shift");
      if (e.altKey) activeModifiers.add("alt");

      for (const binding of bindingsRef.current) {
        // Check if key matches (case insensitive)
        if (e.key.toLowerCase() !== binding.key.toLowerCase()) continue;

        // Check if required modifiers are pressed
        const requiredModifiers = new Set(binding.modifiers || []);
        const hasAllModifiers = Array.from(requiredModifiers).every((mod) =>
          activeModifiers.has(mod)
        );
        const hasOnlyRequiredModifiers = Array.from(activeModifiers).every(
          (mod) =>
            requiredModifiers.has(mod) ||
            // Allow either meta or ctrl when one is required
            (mod === "meta" && requiredModifiers.has("ctrl")) ||
            (mod === "ctrl" && requiredModifiers.has("meta"))
        );

        if (hasAllModifiers && hasOnlyRequiredModifiers) {
          if (binding.preventDefault ?? globalPreventDefault) {
            e.preventDefault();
          }
          binding.handler(e);
          break;
        }
      }
    },
    [enabled, globalPreventDefault, ignoreFocusedElements, blockingElements]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  return {
    /**
     * Get all registered keyboard shortcuts and their descriptions
     */
    getBindings: () =>
      bindings.map(({ key, modifiers, description }) => ({
        key,
        modifiers: modifiers || [],
        description: description || "",
      })),
  };
}

/**
 * Creates a keyboard shortcut binding with a fluent API
 * @param key - The key to bind. Must be a valid KeyboardEvent key.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
 */
export function createBinding(key: KeyCode) {
  const binding: Partial<KeyBinding> = { key };

  const builder = {
    withModifiers(...mods: ModifierKey[]) {
      binding.modifiers = mods;
      return builder;
    },
    withDescription(desc: string) {
      binding.description = desc;
      return builder;
    },
    preventDefault(value = true) {
      binding.preventDefault = value;
      return builder;
    },
    handle(handler: KeyHandler): KeyBinding {
      binding.handler = handler;
      return binding as KeyBinding;
    },
  };

  return builder;
}

/**
 * Combines multiple keyboard maps into one
 */
export function combineKeyMaps(...keyMaps: KeyBinding[][]): KeyBinding[] {
  return keyMaps.flat();
}
