import { Settings } from "lucide-react";
import { useLocation, useParams } from "react-router-dom";

import CanisterRule from "@/components/canister-rule";
import {
  CanisterAdvancedSettingsContent,
  CanisterAlertCard,
  CanisterSettingsContent,
} from "@/components/pages/canister-detail";
import {
  BreadcrumbSeparator,
  BreadcrumbItem,
} from "@/components/ui/breadcrumb";
import { Separator } from "@/components/ui/separator";
import {
  Sidebar,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { Slot } from "@/components/ui/slot-context";
import { AppLink, useRoute } from "@/hooks/queries/team";
import { cn } from "@/lib/ui-utils";

import CanisterOrganizationPage from "./canister-organization";

export default function CanisterSettingsPage() {
  return (
    <CanisterSettingsLayout>
      <CanisterSettingsContent />
    </CanisterSettingsLayout>
  );
}

export function CanisterRulePage() {
  return (
    <CanisterSettingsLayout>
      <CanisterRule />
    </CanisterSettingsLayout>
  );
}

export function CanisterAlertPage() {
  return (
    <CanisterSettingsLayout>
      <CanisterAlertCard />
    </CanisterSettingsLayout>
  );
}

export function CanisterAdvancedPage() {
  return (
    <CanisterSettingsLayout>
      <CanisterAdvancedSettingsContent />
    </CanisterSettingsLayout>
  );
}

export { CanisterOrganizationPage };

export function CanisterSettingsLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <>
      <div className="flex bg-table relative p-2 min-h-full">
        <CanisterSettingsMenu />
        <div className="flex-1 p-2 pl-4">{children}</div>
      </div>
    </>
  );
}

const menuItems = [
  {
    label: "General",
    path: (canisterId?: string) => `/canisters/detail/${canisterId}/settings`,
  },
  {
    label: "Organization",
    path: (canisterId?: string) =>
      `/canisters/detail/${canisterId}/settings/organization`,
  },
  {
    label: "Top-Up Automation",
    path: (canisterId?: string) =>
      `/canisters/detail/${canisterId}/settings/rule`,
  },
  {
    label: "Alerts",
    path: (canisterId?: string) =>
      `/canisters/detail/${canisterId}/settings/alerts`,
  },
  {
    label: "Advanced",
    path: (canisterId?: string) =>
      `/canisters/detail/${canisterId}/settings/advanced`,
  },
];

function CanisterSettingsMenu() {
  const { canisterId: canisterIdString } = useParams();
  const location = useLocation();
  const route = useRoute();

  return (
    <div className="">
      <Sidebar
        collapsible="none"
        className="w-64 border-r border-border/50 sticky top-2 h-auto p-2 pr-4 bg-transparent"
      >
        <SidebarMenu>
          {menuItems.map((item) => (
            <SidebarMenuItem key={item.label}>
              <AppLink to={item.path(canisterIdString)}>
                <SidebarMenuButton
                  className={cn(
                    "bg-muted/0 transition-colors duration-100",
                    route(item.path(canisterIdString)) === location.pathname &&
                      "bg-muted/50 border border-border/50",
                    "hover:bg-muted"
                  )}
                >
                  {item.label}
                </SidebarMenuButton>
              </AppLink>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </Sidebar>
    </div>
  );
}
