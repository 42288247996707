import { Principal } from "@dfinity/principal";

import { SiteBreadcrumb } from "@/components/site-breadcrumb";
import { SlotOutlet } from "@/components/ui/slot-context";
import { useCanisterTableDetailQuery } from "@/hooks/queries/canisters";

interface CanisterBreadcrumbProps {
  canisterId?: Principal;
}

export function CanisterBreadcrumb({ canisterId }: CanisterBreadcrumbProps) {
  const canister = useCanisterTableDetailQuery(canisterId);

  return (
    <>
      <SiteBreadcrumb
        items={[
          {
            title: "Canisters",
            href: "/canisters",
          },
          {
            title: canister.data?.name,
            href: `/canisters/detail/${canisterId?.toText()}`,
          },
        ]}
      />
    </>
  );
}
