import { AppFooter } from "@/components/app-footer";
import { AppSidebar } from "@/components/app-sidebar";
import {
  SidebarInset,
  SidebarProvider,
  useSidebar,
  SidebarTrigger,
} from "@/components/ui/sidebar";
import { SlotProvider } from "@/components/ui/slot-context";
import { cn } from "@/lib/ui-utils";

interface LayoutProps {
  children: React.ReactNode;
}

interface TopBarProps {
  children?: React.ReactNode;
  className?: string;
}

export function TopBar({ children, className }: TopBarProps) {
  const sidebar = useSidebar();

  return (
    <header
      className={cn("flex flex-col shrink-0 items-center gap-0", className)}
    >
      <div
        className={cn(
          "flex items-center gap-2 px-2 pr-0 justify-between w-full min-h-16 mb-1",
          "max-md:px-3",
          !sidebar.open && "h-12 min-h-max"
        )}
      >
        <SidebarTrigger className="md:hidden" />
        {children}
      </div>
    </header>
  );
}

export function Layout({ children }: LayoutProps) {
  return (
    <SlotProvider>
      <SidebarProvider>
        <AppSidebar />
        <SidebarInset className="bg-sidebar">
          {children}
          <AppFooter />
        </SidebarInset>
      </SidebarProvider>
    </SlotProvider>
  );
}
