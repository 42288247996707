import React from "react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";

export interface Props {
  loading?: boolean;
  settings: {
    notifyOnMemoryThresholdReached?: boolean;
  };
  handlers: {
    save: (notifyOnMemoryThresholdReached: boolean) => void;
  };
}

export default function MemoryAlerts({ loading, settings, handlers }: Props) {
  const [enabled, setEnabled] = React.useState(
    settings.notifyOnMemoryThresholdReached
  );

  // Update state on prop change
  React.useEffect(() => {
    setEnabled(settings.notifyOnMemoryThresholdReached);
  }, [settings.notifyOnMemoryThresholdReached]);

  // Save on form submit
  const submit = React.useCallback<React.FormEventHandler>(
    (e) => {
      e.preventDefault();
      if (enabled === undefined) return;
      if (loading) return;
      handlers.save(enabled);
    },
    [enabled]
  );

  return (
    <form onSubmit={submit}>
      <Card>
        <CardHeader>
          <CardTitle>Canister Memory Alerts</CardTitle>
          <CardDescription>
            Receive alerts when your canisters reach memory thresholds.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-5">
            <div>
              <label className="text-sm inline-flex gap-2 items-center">
                <Switch checked={enabled} onCheckedChange={setEnabled} />
                <span>Notify on memory threshold reached</span>
              </label>
            </div>
          </div>
        </CardContent>
        <CardFooter className="w-full flex justify-between">
          <small></small>
          <Button
            variant="secondary"
            children="Save"
            loading={loading}
            type="submit"
          />
        </CardFooter>
      </Card>
    </form>
  );
}
